import { IColumnProps } from 'devextreme-react/data-grid';
import { TabPerfil } from "consts/Tareas";
import { ajustarDecimales } from 'utils/Formatos';

export const ColumsTareas: IColumnProps[] = [
    {
        caption: "Nombre",
        dataField: "Tarea_Nombre",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Asignado a",
        dataField: "cMotion_Perfil_Colaborador_Nombre",
        dataType: "string",
        alignment: "center"
    },
    {
        caption: "Relacionada A",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Termino",
        dataField: "Tarea_Fecha_Termino",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Tipo de Servicio",
        dataField: "Tarea_Tipo_Servicio",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Estatus",
        dataField: "Tarea_Estatus",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Prioridad",
        dataField: "Tarea_Urgencia",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Notas",
        dataField: "Tarea_Nota",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Mes",
        dataField: "Tarea_Mes",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Semana",
        dataField: "Tarea_Semana",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Ultima Actualización",
        dataField: "Tarea_Fecha_Ultima_Actualizacion",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    }
  ];

  export const ColumsTareasSolicitudPago: IColumnProps[] = [
    {
        caption: "Nombre",
        dataField: "Tarea_Nombre",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Asignado a",
        dataField: "cMotion_Perfil_Colaborador_Nombre",
        dataType: "string",
        alignment: "center"
    },
    {
        caption: "Relacionada A",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Termino",
        dataField: "Tarea_Fecha_Termino",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Tipo de Servicio",
        dataField: "Tarea_Tipo_Servicio",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Monto",
        dataField: "Tarea_Monto",
        dataType: "Monetary",
        alignment: "center",
        format: ajustarDecimales,
    },
    {
        caption: "Moneda",
        dataField: "Tarea_Moneda",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Estatus",
        dataField: "Tarea_Estatus",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Prioridad",
        dataField: "Tarea_Urgencia",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Notas",
        dataField: "Tarea_Nota",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Mes",
        dataField: "Tarea_Mes",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Semana",
        dataField: "Tarea_Semana",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Ultima Actualización",
        dataField: "Tarea_Fecha_Ultima_Actualizacion",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    }
  ];

  export const ColumsSubtareasSolicitudPago: IColumnProps[] = [
    {
        caption: "Nombre",
        dataField: "Tarea_Nombre",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Notas",
        dataField: "Tarea_Nota",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Monto",
        dataField: "Tarea_Monto",
        dataType: "Monetary",
        alignment: "center",
        format: ajustarDecimales,
    },
    {
        caption: "Moneda",
        dataField: "Tarea_Moneda",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Termino",
        dataField: "Tarea_Fecha_Termino",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Asignado a",
        dataField: "cMotion_Perfil_Colaborador_Nombre",
        dataType: "string",
        alignment: "center"
    },
    {
        caption: "Relacionada A",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    }
  ];

  export const ColumsClientes: IColumnProps[] = [
    {
        caption: "Guid",
        dataField: "Entidad_Guid",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Cliente",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Vendedor Asignado",
        dataField: "Entidad_Ejecutivo_Admin",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Vencimiento",
        dataField: "A_Pagar",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Estatus",
        dataField: "Dias_Vencidos",
        dataType: "number",
        alignment: "center"
    },
    {
        caption: "Deuda Total MXN",
        dataField: "Total_Deuda_MXN",
        dataType: "number",
        alignment: "center",
        format: "currency"
    },
    {
        caption: "Cantidad Pagada MXN",
        dataField: "Cantidad_Pagada_MXN",
        dataType: "number",
        alignment: "center",
        format: "currency"
    },
    {
        caption: "Tarea",
        dataField: "Tarea_Nombre",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Notas",
        dataField: "Tarea_Nota",
        dataType: "string",
        alignment: "center",
    },
  ];

  export const ColumsProveedores: IColumnProps[] = [
    {
        caption: "Guid",
        dataField: "Entidad_Guid",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Proveedor",
        dataField: "Entidad_Razon_Social",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Fecha de Vencimiento",
        dataField: "A_Pagar",
        dataType: "date",
        alignment: "center",
        format: "dd/MM/yyyy"
    },
    {
        caption: "Estatus",
        dataField: "Dias_Vencidos",
        dataType: "number",
        alignment: "center"
    },
    {
        caption: "Deuda Total MXN",
        dataField: "Total_Deuda_MXN",
        dataType: "number",
        alignment: "center",
        format: "currency"
    },
    {
        caption: "Cantidad Pagada MXN",
        dataField: "Cantidad_Pagada_MXN",
        dataType: "number",
        alignment: "center",
        format: "currency"
    },
    {
        caption: "Tarea",
        dataField: "Tarea_Nombre",
        dataType: "string",
        alignment: "center",
    },
    {
        caption: "Notas",
        dataField: "Tarea_Nota",
        dataType: "string",
        alignment: "center",
    },
  ];

export interface ITabs {
    text: TabPerfil
}