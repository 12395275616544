import axios, { AxiosRequestConfig } from "axios";
import { IFilterData, IFilterMultiple } from "components/interfaces/Interfaces";
import { URL_API_ADMINISTRACION, URL_API_OPERACIONES, URL_API_PERFIL_CLIENTE, URL_API_FIREBASE_APP } from "consts/Services_URL";
import { IRemoteAñosFacturados, IRemoteClientes, IRemoteEstadoCuenta, IRemoteInfoEmbarque, IRemoteMoneda, IRemoteAñosProfit, ICatalogoClientes } from "interfaces/IClientes";
import { IUser } from "interfaces/IUsuario";
import { createNewFilter, createNewFilterMultiple } from "utils/Functions";
import { HttpErrorMessage } from "utils/ResponseAPI";
import { getDataAsync } from "./fetch";
import { IResponse } from "./interfaces/interfaces";
import { IColumnProps } from 'devextreme-react/data-grid';
import { ajustarDecimales } from "utils/Formatos";
import { IFiltersProfit } from '../interfaces/IClientes';
import { RemoteFilterProfit } from "adapters/Clientes";

interface IGetDataFacturacionCliente {
    años: number[];
    moneda: string[];
    clientes: string[];
}

export interface IGetDataProfitInitial {
    data?: {
        años?: number[];
        profitClientes?: ProfitCliente[];
        profitMensualAnual?: ProfitMensualAnual[];
    };
    error?: string | null;
    loading: boolean;
}

export interface ProfitCliente {
    embarqueGuid: string;
    embarque_Numero: string;
    fechaPickUpArribo: Date;
    origen: string;
    cotizacion_Vendedor: string;
    embarque_Total_Piezas: number;
    entidad_Razon_Social: string;
    ventaUSD: number;
    compraUSD: number;
    profitUSD: number;
    profitPorCntr: number;
    porcentaje_Profit: number;
    puntos_LF_USD: number;
}

export interface ProfitMensualAnual {
    mes: number;
    totales: Totale[];
}

export interface Totale {
    anio: number;
    profitMXN: number;
    profitUSD: number;
}

export interface ICatalogosFacturacionClientes {
    años: IFilterMultiple;
    clientes: IFilterMultiple;
    moneda: IFilterData;
}

interface IResponseFacturacionClientes extends IResponse {
    data?: ICatalogosFacturacionClientes
}

interface IResponseProfitClientes extends IResponse {
    data?: ProfitCliente[];
}
interface IReponseCatalogosProfit extends IResponse {
    data?: {
        clientes: IFilterData;
        vendedores: IFilterData,
        años: IFilterMultiple
    }
}
interface IResponseInfoEmbarque extends IResponse {
    data?: IRemoteInfoEmbarque
}

export const ClientesService = (User: IUser) => {

    const { token, divisionSeleccionada: division } = User;
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } }
    /*USAMOS EL SERVICIO DE PROFIT PARA SINCRONIZAR EMBARQUES */
    const profitService = ProfitService(User);
    return {
        getDataPerfilCliente: async (razonSocial: string, fechaInicial: string, fechaFinal: string, filters: string): Promise<IResponse> => {
            let response: any = {};
            await Promise.all([
                getDataAsync(`${URL_API_PERFIL_CLIENTE}/perfilClienteSaldos?razonSocial=${razonSocial}&fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers),
                getDataAsync(`${URL_API_PERFIL_CLIENTE}/perfilClienteDetalle?razonSocial=${razonSocial}&fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers),
                getDataAsync(`${URL_API_PERFIL_CLIENTE}/perfilClienteRangoVencimientos?razonSocial=${razonSocial}&fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers),
                getDataAsync(`${URL_API_PERFIL_CLIENTE}/perfilClienteGastosOriginados?razonSocial=${razonSocial}&fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers),
                getDataAsync(`${URL_API_OPERACIONES}desgloce?filtros=${filters}&division=${division}`, headers),
                getDataAsync(`${URL_API_PERFIL_CLIENTE}/perfilClienteRentabilidad?razonSocial=${razonSocial}&fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers),
            ]).then((responses) => {
                response.data = responses.map((res) => res.data ? res.data : []);
            }).catch((err) => {
                const error = JSON.parse(JSON.stringify(err));
                const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
                response = { ...response, error: messageError, estatus: error.status }
            });
            return response;
        },
        getDataFacturacion: async (filters: string): Promise<IResponse> => {
            const response = await getDataAsync(`${URL_API_ADMINISTRACION}facturacionAnualClientes?filtros=${filters}&division=${division}`, headers)
            return response;
        },
        getDataDeclaracion: async (fechaInicial: string, fechaFinal: string): Promise<IResponse> => {
            const response = getDataAsync(`${URL_API_ADMINISTRACION}declaracionImpuestosCliente?fechaInicio=${fechaInicial}&fechaFinal=${fechaFinal}`, headers);
            return response;
        },
        getCatalogosFacturacionAnual: async (): Promise<IResponseFacturacionClientes> => {
            let response: IResponseFacturacionClientes = { result: false };
            await Promise.all([
                axios.get(`${URL_API_ADMINISTRACION}catalogos/aniosFacturacion?division=${division}`, headers),
                axios.get(`${URL_API_ADMINISTRACION}catalogos/clientes?division=${division}`, headers),
                axios.get(`${URL_API_ADMINISTRACION}catalogos/monedas?division=${division}`, headers)
            ]).then(([añosFacturados, clientes, moneda]) => {
                const añosFacturadosRemote: IRemoteAñosFacturados[] = añosFacturados.data;
                const clienteRemote: IRemoteClientes[] = clientes.data;
                const monedaRemote: IRemoteMoneda[] = moneda.data;

                const catalogos: IGetDataFacturacionCliente = {
                    años: añosFacturadosRemote.map((año) => { return año.anio }),
                    clientes: clienteRemote.map((cliente) => { return cliente.razonSocial }),
                    moneda: monedaRemote.map((moneda) => { return moneda.claveMoneda })
                }
                const añosFilter: IFilterMultiple = createNewFilterMultiple(catalogos.años, 'años', []);
                const clienteFilter: IFilterMultiple = createNewFilterMultiple(catalogos.clientes, 'clientes', []);
                const monedaFilter: IFilterData = createNewFilter(catalogos.moneda, "", "moneda")

                response = { ...response, data: { años: añosFilter, clientes: clienteFilter, moneda: monedaFilter }, result: true }
            }).catch((err) => {
                const error = JSON.parse(JSON.stringify(err));
                const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
                response = { ...response, error: messageError, estatus: error.status }
            })
            return response
        },
        getCatalogosProfit: async (): Promise<IReponseCatalogosProfit> => {
            let response: IResponse = { result: false };
            return await Promise.all([
                getDataAsync(`${URL_API_ADMINISTRACION}catalogos/clientes?division=${division}`, headers),
                getDataAsync(`${URL_API_ADMINISTRACION}catalogos/vendedores?division=${division}`, headers),
                getDataAsync(`${URL_API_ADMINISTRACION}catalogos/aniosProfit`, headers)
            ]).then(([RemoteClientes, RemoteVendedores, RemoteAños]) => {
                const años: number[] = RemoteAños.data?.map((anio: IRemoteAñosProfit) => {
                    return anio.fechaPickUpArribo;
                })
                response = {
                    result: true,
                    data: {
                        clientes: createNewFilter(RemoteClientes.data, "", "clientes"),
                        vendedores: createNewFilter(RemoteVendedores.data, "", "vendedores"),
                        años: createNewFilterMultiple(años, "años", [new Date().getUTCFullYear()])
                    }
                }
                return response;
            }).catch((err: any) => {
                return response;
            });
        },
        getDataProfit: async (filters: IFiltersProfit): Promise<IResponseProfitClientes> => {
            const filtersRemote:string = JSON.stringify(RemoteFilterProfit(filters));
            const response =
                await getDataAsync(`${URL_API_ADMINISTRACION}profit?filtros=${filtersRemote}&division=${division}`, headers);
            return response as IResponseProfitClientes;
        },
        getDataEstadoCuenta: async (filters: string): Promise<IResponse> => {
            return await getDataAsync(`${URL_API_ADMINISTRACION}estadoCtaClientes?filtros=${filters}&division=${division}`, headers);
        },
        getDataEstadoCuentaPerfilCliente: async (filters: string): Promise<IResponse> => {
            return await getDataAsync(`${URL_API_ADMINISTRACION}estadoCtaPerfilCliente?filtros=${filters}&division=${division}`, headers);
        },
        getCatalogosEdoCuenta: async (): Promise<IResponse> => {
            return await axios.get(`${URL_API_ADMINISTRACION}catalogos/clientes?division=${division}`, headers)
                .then((result => {
                    const clientesRemote: IRemoteClientes[] = result.data;
                    const catalogo: string[] = clientesRemote.map((cliente: IRemoteClientes) => { return cliente.razonSocial });
                    const itemsFilter: IFilterMultiple = createNewFilterMultiple(catalogo, "clientes", []);
                    const response: IResponse = { estatus: result.status, data: itemsFilter, result: true };
                    return response;
                })).catch((err) => {
                    const error = JSON.parse(JSON.stringify(err));
                    const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
                    const response: IResponse = { error: messageError, estatus: error.status, result: false };
                    return response
                });
        },
        getCatalogosClientes: async (): Promise<IResponse> => {
            return await axios.get(`${URL_API_ADMINISTRACION}catalogos/clientes?division=${division}`, headers)
                .then((result => {
                    const catalogoClientes: ICatalogoClientes[] = result.data;
                    const response: IResponse = { estatus: result.status, data: catalogoClientes, result: true };
                    return response;
                })).catch((err) => {
                    const error = JSON.parse(JSON.stringify(err));
                    const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
                    const response: IResponse = { error: messageError, estatus: error.status, result: false };
                    return response
                });
        },
        getInfoEmbarque: async (numeroEmbarque: string): Promise<IResponseInfoEmbarque> => {
            const response = await getDataAsync(`${URL_API_ADMINISTRACION}profit/detalleEmbarque?numeroEmbarque=${numeroEmbarque}`, headers);
            return response as IResponseInfoEmbarque;
        },
        syncComission: (embarqueGuid: string) => profitService.syncComission(embarqueGuid),
        updateComission: (embarqueGuid: string) => profitService.updateComission(embarqueGuid)
    }
}
export const ProfitService = (User: IUser) => {
    const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${User.token}` } }
    return {
        syncComission: async (embarqueGuid: string): Promise<boolean> => {
            const response = await getDataAsync(`${URL_API_FIREBASE_APP}/syncComisiones?guid=${embarqueGuid}`);
            return response.result;
        },
        updateComission: async (embarqueGuid: string): Promise<boolean> => {
            const response = await getDataAsync(`${URL_API_ADMINISTRACION}profit/update?guid=${embarqueGuid}`, headers);
            return response.result;
        }
    }
}

export const columsDeclaracionAnual: IColumnProps[] = [
    {
        dataField: 'entidad_RFC',
        dataType: 'string',
        alignment: 'left',
        caption: 'RFC'
    },
    {
        dataField: 'entidad_Razon_Social',
        dataType: 'string',
        alignment: 'left',
        caption: 'Razón Social',
        width: 250,
    },
    {
        dataField: 'division',
        dataType: 'string',
        alignment: 'left',
        caption: 'Division'
    },
    {
        dataField: 'num_Doc',
        dataType: 'string',
        alignment: 'left',
        caption: '# Doc',
        width: 100,
    },
    {
        dataField: 'fecha_Doc',
        dataType: 'date',
        format: "dd/MM/yyyy",
        alignment: 'left',
        caption: 'Fecha Documento'
    },
    {
        dataField: 'total_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Total Factura'
    },
    {
        dataField: 'subtotal_IVA_16',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA 16'
    },
    {
        dataField: 'subtotal_IVA_0',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA 0'
    },
    {
        dataField: 'ivA_No_Objeto',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Subtotal IVA no objeto'
    },
    {
        dataField: 'ivA_16',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVA 16'
    },
    {
        dataField: 'ivarf',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'IVARF'
    },
    {
        dataField: 'importe_Cobrado_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Importe Cobrado Factura'
    },
    {
        dataField: 'fecha_de_Cobro',
        dataType: 'date',
        format: "dd/MM/yyyy",
        alignment: 'left',
        caption: 'Fecha de Cobro'
    },
    {
        dataField: 'tC_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'TC Factura'
    },
    {
        dataField: 'tC_Cobro',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'TC Cobro'
    },
    {
        dataField: 'diferencia',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Diferencia'
    },
    {
        dataField: 'origen',
        dataType: 'string',
        alignment: 'left',
        caption: 'Origen',
        width: 150,
    },
    {
        dataField: 'destino',
        dataType: 'string',
        alignment: 'left',
        caption: 'Destino',
        width: 150,
    },
    {
        dataField: 'documento_Contable_Embarque_Numero',
        dataType: 'string',
        alignment: 'left',
        caption: '# Embarque',
    },
]

export const columnsPuntosCanjeados: IColumnProps[] = [
    {
        dataField: 'factura',
        dataType: 'string',
        alignment: 'left',
        caption: 'Factura'
    },
    {
        dataField: 'concepto',
        dataType: 'string',
        alignment: 'left',
        caption: 'Concepto'
    },
    {
        dataField: 'puntos',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Puntos'
    },
    {
        dataField: 'looking_Forward_Fecha_Canje',
        dataType: 'date',
        format: "dd/MM/yyyy",
        alignment: 'left',
        caption: 'Fecha de Canje en Looking Forward'
    },
    {
        dataField: 'entidad_Razon_Social',
        dataType: 'string',
        alignment: 'left',
        caption: 'Razón Social'
    },
    {
        dataField: 'entidad_Personalizado_Grupo_LF',
        dataType: 'string',
        alignment: 'left',
        caption: 'Grupo LF'
    },
]

export const columnsPuntosLookingForward: IColumnProps[] = [
    {
        dataField: 'razon_Social',
        dataType: 'string',
        alignment: 'left',
        caption: 'Razón Social',
        width: 250,
    },
    {
        dataField: 'vendedor',
        dataType: 'string',
        alignment: 'left',
        caption: 'Vendedor',
        width: 200,
    },
    {
        dataField: 'usuario_LF',
        dataType: 'string',
        alignment: 'left',
        caption: 'Usuario LF',
        width: 150,
    },
    {
        dataField: 'grupo_LF',
        dataType: 'string',
        alignment: 'left',
        caption: 'Grupo LF'
    },
    {
        dataField: 'ultimo_Mes_Carga',
        dataType: 'number',
        alignment: 'left',
        caption: 'Meses transcurridos desde la ultima carga'
    },
    {
        dataField: 'facturacion_Vencida',
        dataType: 'number',
        alignment: 'left',
        caption: 'Días de facturacion vencida'
    },
    {
        dataField: 'total_puntos',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Total de puntos'
    },
    {
        dataField: 'aplica_Canje_Puntos',
        dataType: 'boolean',
        caption: '¿Aplica Canje?'
    },
]

export const columnsOperacionesPerfilCliente: any[] = [
    {
        caption: 'Cantidad de trabajos',
        width: 150,
        dataField: 'cantidad_Trabajos',
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'carrier',
        caption: 'Carrier',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'customer_Service',
        caption: 'Customer Service',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'ejecutivo_Operaciones',
        caption: 'Ejecutivo de Operaciones',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'embarque_Agente_Carga',
        caption: 'Agente de Carga',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'embarque_Modo_Transportacion',
        caption: 'Modo de Transportación',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'embarque_Numero',
        caption: 'Número de Embarque',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'embarque_Total_Piezas',
        caption: 'Total de Piezas',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'estrategia_Destino',
        caption: 'Estrategia Destino',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'estrategia_Origen',
        caption: 'Estrategia Origen',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'fecha_Pick_Up',
        caption: 'Fecha de Pick Up',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'pais_Destino',
        caption: 'País Destino',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'pais_Origen',
        caption: 'País Origen',
        width: 220,
        area: 'row',
        expanded: false,
    },
    {
        dataField: 'piezas_Filtro',
        caption: 'Piezas Filtro',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'puerto_Ciudad_Destino',
        caption: 'Puerto/Ciudad Destino',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'puerto_Ciudad_Origen',
        caption: 'Puerto/Ciudad Origen',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'teu',
        caption: 'TEU',
        width: 220,
        area: 'column',
        expanded: false,
    },
    {
        dataField: 'vendedor',
        caption: 'Vendedor',
        width: 220,
        area: 'column',
        expanded: false,
    }
];

export const columsEdoCuenta: IColumnProps[] = [
    {
        dataField: 'razon_Social',
        dataType: 'string',
        alignment: 'left',
        caption: 'Razón Social',
        width: 275,
        calculateDisplayValue: (data: IRemoteEstadoCuenta): React.ReactNode => {
            return `${data.razon_Social} ${data.dias_Vencidos > 0 ? "❌" : "🟢"}`
        }
    },
    {
        dataField: 'fecha_Documento',
        dataType: 'date',
        alignment: 'left',
        caption: 'Fecha Documento',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'entidad_Ejecutivo_Admin',
        dataType: 'string',
        alignment: 'left',
        caption: 'Admin Executive'
    },
    {
        dataField: 'fecha_Arribo',
        dataType: 'date',
        alignment: 'left',
        caption: 'Fecha Arribo',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'numero_Documento',
        dataType: 'string',
        alignment: 'left',
        caption: '# Documento'
    },
    {
        dataField: 'numero_Embarque',
        dataType: 'string',
        alignment: 'left',
        caption: '# Embarque'
    },
    {
        dataField: 'a_Pagar',
        dataType: 'date',
        alignment: 'left',
        caption: 'A Pagar',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'credito',
        dataType: 'number',
        alignment: 'left',
        caption: 'Credito'
    },
    {
        dataField: 'dias_Vencidos',
        dataType: 'number',
        alignment: 'left',
        caption: 'Dias Vencidos'
    },
    {
        dataField: 'origen',
        dataType: 'string',
        alignment: 'left',
        caption: 'Origen'
    },
    {
        dataField: 'destino',
        dataType: 'string',
        alignment: 'left',
        caption: 'Destino'
    },
    {
        dataField: 'tipo_Flete',
        dataType: 'string',
        alignment: 'left',
        caption: 'Tipo Flete'
    },
    {
        dataField: 'total_Factura',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Total Factura'
    },
    {
        dataField: 'cantidad_Pagada',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Cantidad Pagada'
    },
    {
        dataField: 'saldo_Pendiente',
        dataType: 'number',
        format: ajustarDecimales,
        alignment: 'left',
        caption: 'Saldo Pendiente'
    },
    {
        dataField: 'moneda_Codigo',
        dataType: 'string',
        alignment: 'left',
        caption: 'Moneda Codigo'
    },
    {
        dataField: 'division_Facturada',
        dataType: 'string',
        alignment: 'left',
        caption: 'Division Facturada'
    },
    {
        dataField: 'ejecutivo_Ventas',
        dataType: 'string',
        alignment: 'left',
        caption: 'Ejecutivo Ventas'
    }
]

export interface ISeguimientoTareas{
    Tarea_Id: number;
    Tarea_Nombre: string;
    Tarea_Estatus: string;
    Tarea_Urgencia: string;
    Tarea_Fecha_Termino: Date;
    Tarea_Nota: string;
    Tarea_Fecha_Ultima_Actualizacion: Date;
    Tarea_Fecha_Creacion: Date;
    Entidad_Razon_Social: string;
    cMotion_Perfil_Colaborador_Usuario_cMotion: string;
    Nombre_Colaborador: string;
}

export const columsTareas: IColumnProps[] = [
    {
        dataField: 'Tarea_Nombre',
        dataType: 'string',
        alignment: 'center',
        caption: 'Nombre'
    },
    {
        dataField: 'Tarea_Nota',
        dataType: 'string',
        alignment: 'center',
        caption: 'Descripción'
    },
    {
        dataField: 'Tarea_Estatus',
        dataType: 'string',
        alignment: 'center',
        caption: 'Estatus',
    },
    {
        dataField: 'Tarea_Urgencia',
        dataType: 'string',
        alignment: 'center',
        caption: 'Urgencia'
    },
    {
        dataField: 'Tarea_Fecha_Creacion',
        dataType: 'date',
        alignment: 'center',
        caption: 'Creación',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'Tarea_Fecha_Ultima_Actualizacion',
        dataType: 'date',
        alignment: 'center',
        caption: 'Ultima Actualización',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'Tarea_Fecha_Termino',
        dataType: 'date',
        alignment: 'center',
        caption: 'Fecha de Termino',
        format: 'dd/MM/yyyy'
    },
    {
        dataField: 'Nombre_Colaborador',
        dataType: 'string',
        alignment: 'center',
        caption: 'Responsable',
    },
]