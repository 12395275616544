import { useMemo, useCallback, useEffect } from "react";
import "./styles/estilos.scss";
import { useTask } from "contexts/TaskContext";
import GridTareas from "./components/GridTareas";
import ModalActualizarTarea from "./components/ModalActualizarTarea";
import ModalSubTarea from "./components/ModalSubTarea";
import ModalArchivos from "./components/ModalArchivos";
import MenuFilters from "./components/MenuFilters";
import CreateTaskForm from "./components/CreateTaskForm";
import { ITarea } from "interfaces/ITareas";
import { useTareas } from "./hooks/useTareas";

const ListaPagos = () => {
  const {
    tarea,
    dataTareas,
    tabVisible,
    modalStatus,
    updateTarea,
    deleteTarea,
    responsables,
    entidadDefined,
    setModalStatus,
    handleEditTarea,
    handlerOnSelectTab,
    modalArchivos,
    setModalArchivos,
    dataArchivos,
    handleArchivosTarea,
    uploadFiles,
    addArchivosTarea,
    deleteArchivoTarea,
    setDataArchivos,
    modalSubTareas,
    setModalSubTareas,
    handleSubTareas
  } = useTask();

  const { dataTareasSolPago, getTareasSolicitudPago } = useTareas();

  useEffect(() => {
      getTareasSolicitudPago();
  }, [getTareasSolicitudPago, dataTareas]);

  const memoizedDataTareasSolPago = useMemo(() => dataTareasSolPago.data, [dataTareasSolPago.data]);

  const memoizedDeleteTarea = useCallback((id: number) => {
    deleteTarea(id);
  }, [deleteTarea]);

  const memoizedHandleEditTarea = useCallback((tarea: ITarea) => {
    handleEditTarea(tarea);
  }, [handleEditTarea]);

  return ( 
<>
    {dataTareasSolPago.loading ? (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="icon loading-icon loading-size-lg"></div>
      </div>
    ) : (
      <div className="container-tareas">
        <br />
        <MenuFilters>
          <CreateTaskForm
            isPaymentType={true}
          />
        </MenuFilters>
        <GridTareas
          isPaymentType={true}
          deleteTarea={memoizedDeleteTarea}
          dataTareas={memoizedDataTareasSolPago}
          handleEditTarea={memoizedHandleEditTarea}
          handleArchivosTarea={handleArchivosTarea}
          handleSubTareas={handleSubTareas}
        />
        {modalStatus && (
          <ModalActualizarTarea
            tarea={tarea}
            tabVisible={tabVisible}
            statusModal={modalStatus}
            updateTarea={updateTarea}
            entidades={entidadDefined.data}
            responsables={responsables.data}
            handlerOnSelectTab={handlerOnSelectTab}
            closePopup={() => setModalStatus(!modalStatus)}
          />
        )}
        {modalArchivos && (
          <ModalArchivos
            modalArchivos={modalArchivos}
            archivos={dataArchivos}
            setArchivos={setDataArchivos}
            uploadFiles={uploadFiles}
            addFiles={addArchivosTarea}
            deleteFile={deleteArchivoTarea}
            closePopup={() => setModalArchivos(!modalArchivos)}
          />
        )}
        {modalSubTareas && (
          <ModalSubTarea
            modalSubTarea={modalSubTareas}
            closePopup={() => setModalSubTareas(!modalSubTareas)}
          />
        )}
      </div>
    )}
  </>
  );
};

export default ListaPagos;
