import { memo } from "react";
import { IndexTab } from "consts/Tareas";
import { ScrollView } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import { useTask } from "contexts/TaskContext";
import CreateTaskFormShortcut from "./CreateTaskFormShortcut";

const ModalCrearTarea = () => {
    const {
        tarea,
        tabVisible,
        updateTarea,
        responsables,
        entidadDefined,
        modalTaskShortcut,
        setModalTaskShortcut
      } = useTask();

      const renderTitle = () => {
        return (
          <div className="modal-header">
            <div></div>
            <button onClick={() => setModalTaskShortcut(false)} className="close-button">
              <i className="fas fa-times"></i>
            </button>
          </div>
        );
    };
    
      return (
        <Popup
          width={675}
          height={700}
          dragEnabled={true}
          visible={modalTaskShortcut}
          showCloseButton={false}
          closeOnOutsideClick={false}
          titleRender={renderTitle}
        >
          <ScrollView>
            <>
              <CreateTaskFormShortcut
                tarea={tarea}
                tabVisible={obtenerIndex(tabVisible)}
                updateTarea={updateTarea}
                responsables={responsables}
                entidadDefined={entidadDefined}
              />
            </>
          </ScrollView>
        </Popup>
      );
};
const obtenerIndex = (tabVisible) => {
    return IndexTab[tabVisible.text];
  };

export default memo(ModalCrearTarea);
