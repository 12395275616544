import { memo, useState } from "react";
import { ScrollView } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import { Button } from "components/Index";

const ModalArchivos = ({
  modalArchivos,
  closePopup,
  archivos,
  setArchivos,
  uploadFiles,
  addFiles,
  deleteFile
}) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const tareaId = archivos.Tarea_Id;

  const renderTitle = () => {
      return (
        <div className="modal-header">
          <h3 className="title_task">Archivos</h3>
          <button onClick={closePopup} className="close-button">
            <i className="fas fa-times"></i>
          </button>
        </div>
      );
  };
      
  const renderFiles = () => {
    const handleDownload = (url) => {
      window.open(url, '_blank');
    };

    const handleDelete = async (id, tareaId) => {
      const request = {
        id: id,
        Tarea_Id: tareaId
      }
    await deleteFile(request);
    }

    if (archivos.payload.length === 0) {
        return (
          <div className="no-files">
            <i className="fas fa-folder-open fa-3x"></i>
            <p>No hay archivos para mostrar.</p>
          </div>
        );
    }


    return (
    <div>
      {archivos.payload.length === 0 ? (
        <div className="no-files">
          <i className="fas fa-folder-open fa-3x"></i>
          <p>No hay archivos para mostrar.</p>
        </div>
      ) : (
        <ul className="file-list">
          {archivos.payload.map((archivo, index) => (
            <li key={archivo.Tarea_Archivo_Id} className="file-item">
              <span className="file-icon"><i className="fas fa-file-alt"></i></span>
              <span className="file-name">{archivo.Tarea_Archivo_Nombre}</span>
              <div className="file-actions">
                <button onClick={() => handleDownload(archivo.Tarea_Archivo_Url)} className="action-button">
                  <i className="fas fa-download"></i>
                </button>
                <button onClick={() => handleDelete(archivo.Tarea_Archivo_Id, archivos.Tarea_Id)} className="action-button">
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
  };

  const onFilesSelected = (event) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files).map(file => ({
        file: file,
        uploadFailed: false
      }));
      setFiles([...files, ...selectedFiles]);
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }
  };

  const removeFile = (fileName) => {
    const remainingFiles = files.filter((file) => file.file.name !== fileName);
    setFiles(remainingFiles);
  };

  const handleSubirArchivos = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const uploadedFiles = await uploadFiles(files.map(fileData => fileData.file));

    const successfulUploads = uploadedFiles.filter(file => file.success);
    const nuevosArchivos = successfulUploads.map(file => ({
      nombre: file.name,
      url: file.url
    }));

    const request = {
      Tarea_Id: tareaId,
      Archivos: nuevosArchivos
    }
    await addFiles(request);
    setIsLoading(false);
    setFiles([]);
  }

  return (
    <Popup
      width={500}
      height={450}
      dragEnabled={true}
      visible={modalArchivos}
      titleRender={renderTitle}
      closeOnOutsideClick={true}
      onHiding={closePopup}
    >
        <div className="modal-body">
            <ScrollView className="file-list-container">
                {renderFiles()}
            </ScrollView>
            <form onSubmit={handleSubirArchivos} className="form-upload-files">
                <div className="file-uploader">
                    <label className="select-file-button">
                        Subir Archivo
                        <i className="fas fa-upload" style={{marginLeft: "10px"}}></i>
                        <input type="file" multiple onChange={onFilesSelected} hidden />
                    </label>
                    {files.map((fileData, index) => (
                        <div key={index} className="file-info">
                            {fileData.file.name} - {formatFileSize(fileData.file.size)}
                            <span className="delete-file-button" onClick={() => removeFile(fileData.file.name)}>×</span>
                            {fileData.uploadFailed && <span className="upload-status">Upload failed</span>}
                        </div>
                    ))}
                </div>
                <Button
                    width={"100%"}
                    type="submit"
                    iconHover="Claro"
                    icon={isLoading ? "fas fa-spinner fa-spin" : ""}
                    className="button_add_task"
                    style={{marginBottom: "10px"}}
                    text={isLoading ? "Cargando..." : "Guardar Archivos"}
                    disabled={isLoading}
                />
            </form>
        </div>
    </Popup>
);
};

export default memo(ModalArchivos);
