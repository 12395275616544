import "./styles/estilos.scss";
import { useEffect } from "react";
import { useTareas } from "./hooks/useTareas";
import GridClientes from "./components/GridClientes";
import ModalSeguimiento from "./components/ModalSeguimiento";

const SeguimientoClientes = () => {
  const {
    modalActividadStatus,
    tabVisible,
    seguimientoClientes,
    dataTareasEntidad,
    responsables,
    setModalActividadStatus,
    getSeguimientoClientes,
    handleTareasEntidad,
    onEditTarea,
  } = useTareas();

  useEffect(() => {
    getSeguimientoClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {seguimientoClientes.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="icon loading-icon loading-size-lg"></div>
        </div>
      ) : (
        <div className="container-tareas">
          <hr />
          <br />
          <GridClientes
            dataSeguimientoClientes={seguimientoClientes.data}
            handleTareasEntidad={handleTareasEntidad}
          />
          {modalActividadStatus && (
            <ModalSeguimiento
              modalActividadEstatus={modalActividadStatus}
              closePopup={() => setModalActividadStatus(!modalActividadStatus)}
              tabVisible={tabVisible}
              dataTareasEntidad={dataTareasEntidad}
              onEditTarea={onEditTarea}
              responsables={responsables.data}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SeguimientoClientes;
