import { IRemoteUser, IUser } from '../interfaces/IUsuario';

export const UsuarioAdapter = (user: IRemoteUser) => {
  const Usuario: IUser = {
    name: user.nombre,
    guid: user.guid,
    nameMagaya: user.magaya,
    token: user.token
  }
  return Usuario;
}