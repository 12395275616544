import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Select } from "components/Index";
import { DateBox, Popup } from "devextreme-react";
import { useTask } from "contexts/TaskContext";
import notify from "devextreme/ui/notify";
import {
  tiposEntidades,
  tiposUrgencias,
  tiposEstatus,
  nombresTareas,
  tiposServicios
} from "consts/TareasTipos";
import { IEntidades } from 'interfaces/ITareas';

const CreateTaskFormPopup = () => {
  const {
    responsables,
    getEntidades,
    addTarea,
    data,
    onHandlerSync,
    loading,
    setData
  } = useTask();

  const [nombreTareaSeleccionado, setNombreTareaSeleccionado] = useState("");
  const [tipoEntidad, setTipoEntidad] = useState("");
  const [entidades, setEntidades] = useState<IEntidades[]>([]);
  const [entidadSeleccionada, setEntidadSeleccionada] = useState("");
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");
  const [urgenciaSeleccionada, setUrgenciaSeleccionada] = useState("Baja");
  const [estatusSeleccionado, setEstatusSeleccionado] = useState("Pendiente");
  const [tipoServicioSeleccionado, setTipoServicioSeleccionado] = useState("");
  const [fechaTermino, setFechaTermino] = useState<Date>(new Date());
  const [note, setNote] = useState("");
  const [loadingEntidades, setLoadingEntidades] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [tipo, setTipo] = useState<string | null>(null);
  const [guids, setGuids] = useState("");

  useEffect(() => {
    if (tipoEntidad) {
      setLoadingEntidades(true);
      getEntidades(tipoEntidad).then((data) => {
        setEntidades(data);
        setLoadingEntidades(false);
      });
    } else {
      setEntidades([]);
    }
  }, [tipoEntidad, getEntidades]);

  const handlerCreateTarea = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const nuevaTarea = {
      Tarea_Nombre: nombreTareaSeleccionado,
      Tarea_Urgencia: urgenciaSeleccionada,
      Tarea_Entidad_Cliente_Guid: entidadSeleccionada,
      Tarea_Colaborador_Guid: responsableSeleccionado,
      Tarea_Fecha_Termino: fechaTermino,
      Tarea_Nota: note,
      Tarea_Estatus: estatusSeleccionado,
      Tarea_Tipo_Servicio: tipoServicioSeleccionado,
    };

    await addTarea(nuevaTarea);

    setNombreTareaSeleccionado("");
    setTipoEntidad("");
    setEntidadSeleccionada("");
    setResponsableSeleccionado("");
    setUrgenciaSeleccionada("Baja");
    setEstatusSeleccionado("Pendiente");
    setFechaTermino(new Date());
    setNote("");

    notify("Tarea agregada exitosamente", "success", 2000);
  };

  return (
    <>
      <Popup
        visible={openModal}
        onHiding={() => setOpenModal(false)}
        title="Agregar GUID o Job Number"
        width={300}
        height={400}
        contentRender={() => (
          <>
            <Select
              title="Tipo"
              item={{
                key: "Sync",
                title: "Sync",
                items: [
                  "Embarque",
                  "Cliente",
                  "Proveedor",
                  "Facturas de Pago",
                  "Facturas de Cobro",
                  "Cheques",
                  "Pagos",
                  "Cotizaciones",
                ],
              }}
              onChangeSelect={(e) => {
                setTipo(e.value);
              }}
            />
            <textarea
              className="textarea"
              placeholder="GUIDs"
              style={{ width: "100%", marginTop: 10, height: 200 }}
              value={guids}
              onChange={(e) => setGuids(e.target.value)}
            />
            <Button
              disabled={loading}
              icon={`fas fa-${loading ? "spin" : "sync-alt"}`}
              text="Agregar GUIDs"
              width={250}
              iconHover="Claro"
              style={{ backgroundColor: "#10A800" }}
              onClick={async () => {
                const guidsArray = guids.split("\n").filter(Boolean);

                if (!tipo || !guidsArray.length) return;

                const uniqueGuids = Array.from(new Set(guidsArray));
                const newGuids = uniqueGuids.filter(
                  (guid) =>
                    !data.some((item) => item.reporte_Sync_Guid === guid)
                );

                const syncData = newGuids.map((guid) => ({
                  reporte_Sync_Tipo: tipo,
                  reporte_Sync_Guid: guid,
                  isDeleted: false,
                }));

                setData((prevData) => [...prevData, ...syncData]);
                await onHandlerSync(syncData);
                setOpenModal(false);
                setGuids("");
              }}
            />
          </>
        )}
      />
      <div className="container-filters">
      <form onSubmit={handlerCreateTarea}>
        <h2>Crear Tarea</h2>
        <div className="select-container label_input_task">
          <label htmlFor="nombreTarea">Nombre de la Tarea</label>
          <select
            id="nombreTarea"
            name="nombreTarea"
            value={nombreTareaSeleccionado}
            onChange={(e) => setNombreTareaSeleccionado(e.target.value)}
          >
            {nombresTareas.map((nombreTarea) => (
              <option key={nombreTarea.value} value={nombreTarea.value}>
                {nombreTarea.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="tipoEntidad">Tipo de Entidad</label>
          <select
            id="tipoEntidad"
            name="tipoEntidad"
            value={tipoEntidad}
            onChange={(e) => setTipoEntidad(e.target.value)}
          >
            {tiposEntidades.map((entidad) => (
              <option key={entidad.value} value={entidad.value}>
                {entidad.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="label_input_task">
          <label htmlFor="entidadAutocomplete">Entidad</label>
          <Autocomplete
            id="entidadAutocomplete"
            size="small"
            options={entidades}
            loading={loadingEntidades}
            disabled={entidades.length === 0}
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.Entidad_Razon_Social}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option.Entidad_Guid}>
                {option.Entidad_Razon_Social}
              </li>
            )}
            onChange={(event, newValue) =>
              setEntidadSeleccionada(newValue ? newValue.Entidad_Guid : "")
            }
          />
        </div>
        <div className="label_input_task">
          <label htmlFor="responsableAutocomplete">
            Responsable de la tarea
          </label>
          <Autocomplete
            size="small"
            options={responsables.data!}
            id="responsableAutocomplete"
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.colaborador}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option.guid}>
                {option.colaborador}
              </li>
            )}
            onChange={(event, newValue) =>
              setResponsableSeleccionado(newValue ? newValue.guid : "")
            }
          />
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="tipoServicio">Tipo de Servicio</label>
          <select
            id="tipoServicio"
            name="tipoServicio"
            value={tipoServicioSeleccionado}
            onChange={(e) => setTipoServicioSeleccionado(e.target.value)}
          >
            {tiposServicios.map((tipoServicio) => (
              <option key={tipoServicio.value} value={tipoServicio.value}>
                {tipoServicio.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="urgencia">Prioridad</label>
          <select
            id="urgencia"
            name="urgencia"
            value={urgenciaSeleccionada}
            onChange={(e) => setUrgenciaSeleccionada(e.target.value)}
          >
            {tiposUrgencias.map((urgencia) => (
              <option key={urgencia.value} value={urgencia.value}>
                {urgencia.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="estatus">Estatus</label>
          <select
            id="estatus"
            name="estatus"
            value={estatusSeleccionado}
            onChange={(e) => setEstatusSeleccionado(e.target.value)}
          >
            {tiposEstatus.map((estatus) => (
              <option key={estatus.value} value={estatus.value}>
                {estatus.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="label_input_task">
          <label htmlFor="Tarea_Fecha_Termino">Fecha Termino</label>
          <DateBox
            type="date"
            value={fechaTermino}
            pickerType="calendar"
            name="Tarea_Fecha_Termino"
            className="date_input_task"
            onValueChanged={(e) => setFechaTermino(e.value)}
          />
        </div>
        <div className="label_input_task">
          <label htmlFor="note">Nota</label>
          <input
            type="text"
            name="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <Button
          icon=""
          type="submit"
          width={"100%"}
          iconHover="Claro"
          text="CREAR TAREA"
          className="button_add_task"
        />
      </form>
    </div>
    </>
  );
};

export default CreateTaskFormPopup;
