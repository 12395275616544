import { useContext } from "react"
import axios, { AxiosRequestConfig } from 'axios';
import { NominaContext, IContextNomina } from 'contexts/NominaContext';
import { IUser } from 'interfaces/IUsuario';
import {
  BILLETES, DIA_PAGO_SEMANAL, MethodSave, ROLES, MODULESNOMINA,
  MONTO_CALCULO_VACACIONES, TIPOSPAGO_PRETERMINADO
} from 'consts/TH';
import { IFiltersPagoNomina, IRemoteTipoPago, IRemoteNomina, IReporteAsimilado, INomina, IPeriodos, IImpuestosNomina, IReporteTotales, IBilletes, ISaveNominas, IOpcionesReportes, IPeriodosPagados, IRemoteFilterReporteAcumulado, IReporteAcumulado, ITotalMensualAcumulado, IReporteComisiones } from 'interfaces/ITalentoHumano';
import { IResponse } from "./interfaces/interfaces";
import { URL_API_COMISIONES, URL_API_TH } from "consts/Services_URL";
import { getDataAsync, sendDataAsync } from './fetch';
import { PeriodosService } from 'functions/Nomina';
import { IColumnProps } from 'devextreme-react/data-grid';
import { ajustarDecimales } from 'utils/Formatos';
import { Workbook, Worksheet } from 'exceljs';
import saveAs from "file-saver";
import { HttpErrorMessage } from "utils/ResponseAPI";
import { IContextNominaPagada, NominaPagadaContext } from "contexts/NominaPagadaContext";
import { Meses } from "utils/Global";
import { IFilterReporteAcumulado, IFilterReporteComisiones } from '../interfaces/ITalentoHumano';
import notify from "devextreme/ui/notify";

export const useNomina = (): IContextNomina => {
  return useContext(NominaContext);
}
export const useNominaPagada = (): IContextNominaPagada => {
  return useContext(NominaPagadaContext);
}

export const NominaService = (User: IUser) => {
  /**PRIVATE */
  const { token } = User;
  const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };

  const validarTotal = (nomina: INomina, diasTipoPeriodo: number): number => {
    const {
      temporalDiasCalculados: diasCalculados = 0,
      sueldoSobrepasaIMSS, sueldoPorPagar = 0,
      temporalPersepciones = 0
    } = nomina;

    if (diasTipoPeriodo === diasCalculados) {
      return sueldoSobrepasaIMSS;
    }
    return sueldoPorPagar - temporalPersepciones;
  }
  const recalcularTotal = (nomina: INomina, diasTipoPeriodo: number): number => {
    const { sueldoSobrepasaIMSS, diasVacaciones, diasDescontar } = nomina;  
    const montoVacaciones = (((sueldoSobrepasaIMSS / diasTipoPeriodo) * diasVacaciones) * MONTO_CALCULO_VACACIONES) +
      (((sueldoSobrepasaIMSS / diasTipoPeriodo) * diasDescontar) * -1);
    return montoVacaciones;
  }

  /**PUBLIC */
  return {
    getImpuestos: async (): Promise<IResponse> => {
      return Promise.all([
        axios.get(`${URL_API_TH}catalogos/iva`, headers),
        axios.get(`${URL_API_TH}catalogos/porcentajeFacturacion`, headers)
      ]).then(([iva, porcentajeFacturacion]) => {
        const impuestos: IImpuestosNomina = {
          iva: iva.data,
          porcentajeFacturacion: porcentajeFacturacion.data
        }
        return { data: impuestos, result: true }
      }).catch((err: any) => {
        const error = JSON.parse(JSON.stringify(err));
        const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
        return { result: false, error: messageError }
      })
    },
    getPeriodos: async (tipoPago: TIPOSPAGO_PRETERMINADO): Promise<IPeriodos[]> => {
      const periodosService = PeriodosService();
      //VALIDAMOS 
      if (tipoPago === "QUINCENAL") {
        const periodosQuincenales = periodosService.getPeriodosQuincenales();
        const periodosQuincenalesPagados: IPeriodosPagados[] = await new Promise((resolve) => {
          NominaService(User).getPeriodosPagados(1, (periodos, result) => {
            !result && notify("Ocurrio un error al obtener los periodos", "error", 4000);
            resolve(periodos);
          });
        });

        const periodosPagadosStrings = periodosQuincenalesPagados.flatMap((p) => 
          p.periodos.map((periodo) => periodo.periodo)
        );

        // Filtra los periodos que no han sido pagados
        const periodosQuincenalesNoPagados = periodosQuincenales.filter((periodo) => 
          periodo.periodo !== undefined && !periodosPagadosStrings.includes(periodo.periodo)
        );
        return periodosQuincenalesNoPagados;
      }
      if (tipoPago === "SEMANAL") {
        const periodosSemanales = periodosService.getPeriodosSemanales(DIA_PAGO_SEMANAL)
        const periodosSemanalesPagados: IPeriodosPagados[] = await new Promise((resolve) => {
          NominaService(User).getPeriodosPagados(2, (periodos, result) => {
            !result && notify("Ocurrio un error al obtener los periodos", "error", 4000);
            resolve(periodos);
          });
        });
        const periodosPagadosStrings = periodosSemanalesPagados.flatMap((p) => 
          p.periodos.map((periodo) => periodo.periodo)
        );
        
        // Filtra los periodos que no han sido pagados
        const periodosSemanalesNoPagados = periodosSemanales.filter((periodo) => 
          periodo.periodo !== undefined && !periodosPagadosStrings.includes(periodo.periodo)
        );
  
        return periodosSemanalesNoPagados;
      }
      if (tipoPago === "ESPECIAL") {
        const periodosMensuales = periodosService.getPeriodosMensuales();
        const periodosMensualesPagados: IPeriodosPagados[] = await new Promise((resolve) => {
          NominaService(User).getPeriodosPagados(3, (periodos, result) => {
            !result && notify("Ocurrio un error al obtener los periodos", "error", 4000);
            resolve(periodos);
          });
        });
        const periodosPagadosStrings = periodosMensualesPagados.flatMap((p) => 
          p.periodos.map((periodo) => periodo.periodo)
        );
        // Filtra los periodos que no han sido pagados
        const periodosMensualesNoPagados = periodosMensuales.filter((periodo) => 
          periodo.periodo !== undefined && !periodosPagadosStrings.includes(periodo.periodo)
        );
        return periodosMensualesNoPagados;
      }
      const periodosAnuales = periodosService.getPeriodoAnual();
      const periodosAnualesPagados: IPeriodosPagados[] = await new Promise((resolve) => {
        NominaService(User).getPeriodosPagados(4, (periodos, result) => {
          !result && notify("Ocurrio un error al obtener los periodos", "error", 4000);
          resolve(periodos);
        });
      });
      const periodosPagadosStrings = periodosAnualesPagados.flatMap((p) => 
        p.periodos.map((periodo) => periodo.periodo)
      );
      // Filtra los periodos que no han sido pagados
      const periodosAnualesNoPagados = periodosAnuales.filter((periodo) => 
        periodo.periodo !== undefined && !periodosPagadosStrings.includes(periodo.periodo)
      );
      return periodosAnualesNoPagados;

    },
    verificaPeriodos: async (periodo: string): Promise<IResponse> => {
      const rol: ROLES = User.rol as ROLES;
      if (rol === "SuperAdmin") {
        return { data: false, result: true };
      }
      return await getDataAsync(`${URL_API_TH}nominas/pagadas/periodos/${periodo}`, headers);
    },
    getNominas: async (filters: IFiltersPagoNomina, callback: (nominas: INomina[]) => void): Promise<boolean> => {
      const periodosService = PeriodosService();
      const { formaPago, periodo, tipoPago } = filters;
      const { cMotion_Tipo_Nomina_Id: id } = tipoPago!;
      const response = await
        getDataAsync(`${URL_API_TH}nominas?forma=${formaPago}&fechaPago=${periodo?.final.toLocaleDateString()}&tipoPago=${id}&periodo=${periodo?.periodo}`, headers);

      if (!response.result) {
        callback([]);
        return false;
      }
      const nominasRemote: IRemoteNomina[] = response.data;
      const mes: number = periodo!.final.getMonth();
      const año: number = periodo!.final.getUTCFullYear();
      const diaValidoPago: number = periodosService.validarDiasDescanso(periodo!.final);
      const fechaValidaPago: Date = new Date(año, mes, diaValidoPago);
      /*ASIGNAMOS LOS NUEVOS VALORES DE LOS FILTROS QUE SE SELECCIONARON*/
      const newNominas: INomina[] = nominasRemote.map((nomina) => {
        const { diasDescontar, diasCalculados } = nomina;
        return {
          ...nomina,
          fechaPago: fechaValidaPago,
          Persepciones_Deducciones: 0,
          periodo: periodo!.periodo,
          tipoPago: tipoPago!,
          temporalDiasCalculados: diasDescontar > 0 ? (diasCalculados + diasDescontar) : diasCalculados,
          temporalPersepciones: nomina.persepciones_Deducciones,
          sueldoPorPagar: nomina.total_a_pagar
        }
      });
      callback(newNominas);
      return true;
    },
    getAguinaldos: async (filters: IFiltersPagoNomina, callback: (aguinaldos: INomina[]) => void): Promise<boolean> => {
      const tipoPago: IRemoteTipoPago = filters.tipoPago!;
      const periodo = filters.periodo?.periodo
      const { data, result } = await getDataAsync(`${URL_API_TH}nominas/aguinaldos?periodo=${periodo}`, headers);
      if (!result) {
        callback([]);
        return false;
      }
      const aguinaldosRemote: IRemoteNomina[] = data;
      const newAguinaldos: INomina[] = aguinaldosRemote.map((aguinaldo) => {
        return {
          ...aguinaldo,
          tipoPago
        }
      })
      callback(newAguinaldos);
      return true;
    },
    getEspeciales: async (filters: IFiltersPagoNomina, callback: (especiales: INomina[]) => void): Promise<boolean> => {
      const { tipoPago, periodo } = filters;
      const fechaPago: Date = periodo?.final!;
      const { data, result } = await getDataAsync(`${URL_API_TH}nominas/especiales?fechaPago=${fechaPago.toLocaleString().split(" ")[0]}`, headers);
      if (!result) {
        callback([]);
        return false;
      }
      const especialesRemote: IRemoteNomina[] = data;
      const newEspeciales: INomina[] = especialesRemote.map((especial) => {
        return {
          ...especial,
          tipoPago: tipoPago!
        }
      })
      callback(newEspeciales);
      return true;
    },
    recalcularVacaciones_Descuento: (nomina: INomina): INomina => {
      const { extra, diasDescontar, temporalDiasCalculados, tipoPago } = nomina;
      const { cMotion_Tipo_Nomina_Dias: diasTipoPeriodo } = tipoPago!;
      const totalAPagar: number = validarTotal(nomina, diasTipoPeriodo);
      const diasCalculados: number = (temporalDiasCalculados || 0) - diasDescontar;
      const montoVacaciones_Descuento: number = recalcularTotal(nomina, diasTipoPeriodo);
      const total: number = totalAPagar + extra + montoVacaciones_Descuento;   
      console.log(nomina)   
      const nominaRecalculada: INomina = {
        ...nomina,
        persepciones_Deducciones: montoVacaciones_Descuento,
        total_a_pagar: total,
        diasCalculados
      }
      return nominaRecalculada;
    },
    saveNominasOnBD: async (nominas: INomina[], iva: number, method: MethodSave,
      callback: (result: ISaveNominas) => Promise<void>): Promise<void> => {
      const nominasRemote: IRemoteNomina[] = nominas.map((nomina) => {
        const { tipoPago, temporalDiasCalculados, sueldoPorPagar, temporalPersepciones, ...rest } = nomina;
        return {
          ...rest,
          idTipoPago: tipoPago?.cMotion_Tipo_Nomina_Id!,
          tipoPago: tipoPago?.cMotion_Tipo_Nomina_Descripcion!,
          iva: iva,
          periodo: rest.periodo!
        }
      });
      const response = method === "NOMINA" ?
        await sendDataAsync(`${URL_API_TH}nominas`, nominasRemote, "POST", headers.headers) :
        await sendDataAsync(`${URL_API_TH}nominas/prenomina`, nominasRemote, "POST", headers.headers);
      const message: string = !response.result ?
        response.error :
        method === "NOMINA" ?
          "Se pagaron las nominas correctamente" :
          "Se guardaron los datos en prenominas correctamente";
      callback({
        message,
        wasSaved: response.result,
        paidPeriod: nominas[0].periodo!
      });

    },
    getNominasPagadas: async (periodo: string, callback: (nominas: IRemoteNomina[]) => void): Promise<boolean> => {
      const response = await getDataAsync(`${URL_API_TH}nominas/pagadas?periodo=${periodo}`, headers);
      if (!response.result) {
        callback([]);
        return false;
      }
      const nominas: IRemoteNomina[] = response.data;
      callback(nominas);
      return true;
    },
    getPeriodosPagados: async (tipoPagoId: number, callback: (periodos: IPeriodosPagados[], result: boolean) => void): Promise<void> => {
      const response = await getDataAsync(`${URL_API_TH}nominas/pagadas/periodos?tipoPago=${tipoPagoId}`, headers);
      if (!response.result) {
        callback([], false);
      }
      const periodos: IPeriodosPagados[] = response.data;
      callback(periodos, true);
    },
    reopenNominaPagada: async (periodo: string, callback: (result: boolean) => void): Promise<void> => {
      try{
        const response = await sendDataAsync(`${URL_API_TH}nominas/reabrir`, periodo, "POST", {"Content-Type": "application/json",...headers.headers})
        callback(response.result);
      } catch (error) {
        callback(false);
      }
    },
    deshabilitarOpcionesReportes: (tipoPago: TIPOSPAGO_PRETERMINADO): IOpcionesReportes => {
      if (tipoPago === "ESPECIAL") {
        return { asimilados: false, billetiza: false, recibos: true }
      }
      if (tipoPago === "SEMANAL") {
        return { asimilados: true, billetiza: false, recibos: false }
      }
      /**AGUINALDO O QUINCENAL */
      return { asimilados: false, billetiza: false, recibos: false }
    },
    showColumnsPagoNomina: (module: MODULESNOMINA, tipoPago?: IRemoteTipoPago): IColumnProps[] => {

      if (!tipoPago) return [];

      const rol: ROLES = User.rol as ROLES;
      const { cMotion_Tipo_Nomina_Descripcion: tipo } = tipoPago;
      const allowEditTotal: boolean = module === "PAGADA" ? false : rol === "SuperAdmin";
      const allowEdit: boolean = module === "PAGADA" ? false : true;

      const colums: IColumnProps[] = [
        {
          caption: "Colaborador",
          dataField: "colaborador",
          dataType: "string",
          alignment: "left",
          allowEditing: false
        },
        {
          caption: "Sueldo Sobrepasa IMSS",
          dataField: "sueldoSobrepasaIMSS",
          dataType: "string",
          visible: tipo !== "ESPECIAL",
          alignment: "left",
          allowEditing: false,
          format: ajustarDecimales
        },
        {
          caption: "Fecha de pago",
          dataField: "fechaPago",
          dataType: "date",
          alignment: "left",
          format: 'dd/MM/yyyy',
          allowEditing: false
        },
        {
          caption: "Forma de pago",
          dataField: "formaPago",
          dataType: "string",
          alignment: "left",
          allowEditing: false
        }
      ];

      if (tipo === "AGUINALDO") {
        colums.push({
          caption: "Fecha Ingreso",
          dataField: "fechaIngreso",
          dataType: "date",
          alignment: "left",
          allowEditing: false
        }, {
          caption: "Días Calculados",
          dataField: "diasCalculados",
          dataType: "number",
          alignment: "left",
          allowEditing: false
        },
          {
            caption: "Oficina",
            dataField: "oficina",
            dataType: "string",
            alignment: "left",
            allowEditing: false
          });
      }
      if (tipo === "QUINCENAL" || tipo === "SEMANAL") {
        colums.push(
          {
            caption: "Días Calculados",
            dataField: "diasCalculados",
            dataType: "number",
            alignment: "left",
            allowEditing: false
          },
          {
            caption: "Días Vacaciones",
            dataField: "diasVacaciones",
            dataType: "number",
            alignment: "left",
            allowEditing: allowEdit,
            validationRules: [{
              type: "required",
              message: "Requerido"
            },
            {
              type: 'pattern',
              pattern: /^[0-9]+$/
            }]
          },
          {
            caption: "Días a Descontar",
            dataField: "diasDescontar",
            dataType: "number",
            alignment: "left",
            allowEditing: allowEdit,
            validationRules: [{
              type: "required",
              message: "Requerido"
            },
            {
              type: 'pattern',
              pattern: /^[0-9]+$/
            }]
          },
          {
            caption: "Persepciones/Deducciones",
            dataField: "persepciones_Deducciones",
            dataType: "number",
            alignment: "left",
            allowEditing: false,
            format: ajustarDecimales
          },
          {
            caption: "Monto Extra",
            dataField: "extra",
            dataType: "number",
            alignment: "left",
            allowEditing: allowEdit,
            format: ajustarDecimales,
            validationRules: [
              {
                type: 'pattern',
                pattern: /^-?\d*(\.\d+)?$/
              }]
          }
        );
      }
      colums.push(
        {
          caption: "Notas extra",
          dataField: "notasExtra",
          width: 200,
          alignment: "left",
          allowEditing: allowEdit
        },
        {
          caption: "Total",
          dataField: "total_a_pagar",
          width: 250,
          format: ajustarDecimales,
          allowEditing: allowEditTotal,
          alignment: "left"
        }
      );
      return colums;
    }
  }
}

export const ReportsService = (User: IUser) => {
  /**PRIVATE */
  const { token } = User;
  const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };

  const generarExcelAsimilado = async (nominas: IReporteAsimilado[], porcentajeFacturacion: number): Promise<boolean> => {
    try {
      const iva: number = nominas[0].iva!;
      const periodo: string = nominas[0].periodoPago!;

      const workbook: Workbook = new Workbook();
      workbook.creator = "CROSSMOTION LOGISTICS S.A DE C.V"
      workbook.created = new Date();
      const workSheet: Worksheet = workbook.addWorksheet(`ASIMILADOS - ${periodo}`);
      //Establecemos las columnas del Excel
      workSheet.columns = [
        { header: "Nombre", key: "nombreColaborador", width: 50 },
        { header: "Cuenta Santander", key: "cuenta", width: 30 },
        { header: "CLABE", key: "clabe", width: 30 },
        { header: "RFC", key: "rfc", width: 20 },
        { header: "Total", key: "subtotal", width: 30 }
      ];

      let subtotalAcumulado: number = 0;
      nominas.forEach((item) => {
        workSheet.addRow({ ...item, subtotal: ajustarDecimales(item.subtotal) });
        subtotalAcumulado += item.subtotal;
      });

      //Add subtotal
      const totalAPagar: number = subtotalAcumulado + (subtotalAcumulado * (porcentajeFacturacion / 100));
      const IVA: number = (totalAPagar * (iva / 100));
      workSheet.addRow({ rfc: "Total a pagar:", subtotal: ajustarDecimales(subtotalAcumulado) }).font = { bold: true };
      workSheet.addRow({ rfc: `Subtotal (+ ${porcentajeFacturacion} %):`, subtotal: ajustarDecimales(totalAPagar) }).font = { bold: true };
      workSheet.addRow({ rfc: `IVA ${iva}%:`, subtotal: ajustarDecimales(IVA) }).font = { bold: true };
      workSheet.addRow({ rfc: "Total facturado:", subtotal: ajustarDecimales(totalAPagar + (IVA)) }).font = { bold: true };
      workSheet.eachRow((Row) => {
        Row.eachCell((Cell) => {
          Cell.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
        });
      });

      workSheet.getRow(1).font = { bold: true };
      await workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `ASIMILADOS - ${periodo}.xlsx`);
      });
      return true;
    } catch (err: any) {
      return false;
    }
  }
  const generarExcelEfectivo = async (nominas: IRemoteNomina[]): Promise<boolean> => {
    try {
      const periodo: string = nominas[0].periodo!;
      const workbook: Workbook = new Workbook();
      workbook.creator = "CROSSMOTION LOGISTICS S.A DE C.V"
      workbook.created = new Date();
      const worksheet: Worksheet = workbook.addWorksheet(`Efectivo`);

      worksheet.columns = [
        { header: "Colaborador", key: "colaborador", width: 50 },
        { header: "Total efectivo", key: "total_a_pagar", width: 20 },
        { header: "Billetes $ 500", key: "500", width: 15 },
        { header: "Billetes $ 200", key: "200", width: 15 },
        { header: "Billetes $ 100", key: "100", width: 15 },
        { header: "Billetes $ 50", key: "50", width: 15 },
        { header: "Billetes $ 20", key: "20", width: 15 },
        { header: "Monedas", key: "monedas", width: 15 }
      ]

      let ROW_TOTALES: IReporteTotales = {
        colaborador: "Totales",
        total_a_pagar: "0",
        "500": 0,
        "200": 0,
        "100": 0,
        "50": 0,
        "20": 0,
        monedas: 0
      };

      let totalAcumulado: number = 0;
      const arrayPagoEfectivo = nominas.map((item) => {
        totalAcumulado += item.total_a_pagar;
        let restante: number = item.total_a_pagar;
        let cantidades: IBilletes = { "500": 0, "200": 0, "100": 0, "50": 0, "20": 0 };
        const billetes = Object.keys(cantidades).reverse();
        billetes.forEach((billete: string) => {
          const valorBillete: number = parseInt(billete);
          let residuo: number = restante % valorBillete;
          let cantidadBilletes: number = (restante - residuo) / valorBillete;
          restante = restante - (cantidadBilletes * valorBillete);
          cantidades = { ...cantidades, [billete]: cantidadBilletes };
          const totalBilletes = ROW_TOTALES[billete as BILLETES] + cantidadBilletes;
          ROW_TOTALES = { ...ROW_TOTALES, [billete]: totalBilletes };
        });
        ROW_TOTALES = { ...ROW_TOTALES, monedas: ROW_TOTALES.monedas + Math.round(restante) };
        return {
          colaborador: item.colaborador,
          total_a_pagar: ajustarDecimales(item.total_a_pagar),
          ...cantidades,
          monedas: Math.round(restante)
        };
      });

      worksheet.addRows(arrayPagoEfectivo);
      worksheet.addRow({ ...ROW_TOTALES, total_a_pagar: ajustarDecimales(totalAcumulado) }).font = { bold: true };

      worksheet.eachRow((Row) => {
        Row.eachCell((Cell) => {
          Cell.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
        });
      });
      worksheet.getRow(1).font = { bold: true };

      await workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Billetiza - ${periodo}.xlsx`);
      });
      return true;
    } catch (err: any) {
      return false;
    }
  }
  const generarExcelRecibos = async (nominas: IRemoteNomina[]): Promise<boolean> => {
    try {
      const periodo: string = nominas[0].periodo!;
      const workbook: Workbook = new Workbook();
      workbook.creator = "CROSSMOTION LOGISTICS S.A DE C.V"
      workbook.created = new Date();
      const worksheet: Worksheet = workbook.addWorksheet(`Efectivo`);

      worksheet.columns = [
        { header: "", key: "column1", width: 45 },
        { header: "", key: "column2", width: 35 }
      ]

      nominas.forEach((nomina) => {
        worksheet.addRow({ column1: "Periodo", column2: "A pagarse el" }, "bold").font = { bold: true };
        worksheet.addRow({ column1: nomina.periodo, column2: new Date(nomina.fechaPago).toLocaleDateString() });
        worksheet.addRow({ column1: "", column2: "" });
        worksheet.addRow({ column1: "Nombre", column2: "Forma de pago" }, "bold").font = { bold: true };
        worksheet.addRow({ column1: nomina.colaborador, column2: nomina.formaPago });
        worksheet.addRow({ column1: "", column2: "" });
        worksheet.addRow({ column1: "Total recibido", column2: "Firma" }, "bold").font = { bold: true };
        worksheet.addRow({ column1: ajustarDecimales(nomina.total_a_pagar), column2: "________________________________" });
        worksheet.addRow({ column1: "", column2: "" });
        worksheet.addRow({ column1: "", column2: "" });
      });

      worksheet.getRow(1).font = { bold: true };

      await workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Recibos - ${periodo}.xlsx`);
      });
      return true;
    } catch (err: any) {
      return false;
    }
  }
  const generarExcelAcumulado = async (reporte: IReporteAcumulado[], meses: number[]): Promise<boolean> => {
    try {
      /**CREAMOS EL ARCHIVO CON EXCEL CON SU HOJA */
      const workbook: Workbook = new Workbook();
      workbook.creator = "CROSSMOTION LOGISTICS S.A DE C.V"
      workbook.created = new Date();
      const worksheet: Worksheet = workbook.addWorksheet(`Reporte Acumulado`);

      let cellMergesPoint: number = 4; //APUNTADOR EN LA 3RA CULUMNA QUE ES DONDE EMPIEZA EL CONTENIDO DINAMICO
      let totalColums: { width: number }[] = []; //ASIGNAR EL TAMAÑO A CADA COLUMNA
      let totalColaboradorMensual: ITotalMensualAcumulado[] = [];
      const namesMeses = Meses;
      /**RECORREMOS LOS MESES SELECCIONADOS PARA CREAR LAS CABEZERAS DEL EXCEL */
      meses.sort(compareMonths).forEach((mes: number) => {
        totalColaboradorMensual = [...totalColaboradorMensual, { mes: mes, EFECTIVO: 0, ASIMILADO: 0 }];//CREAMOS LOS OBJETOS DINAMICAMENTE
        let nextCellMerge: number = cellMergesPoint + 1;
        worksheet.mergeCells(1, cellMergesPoint, 1, nextCellMerge);//COMBINAMOS DOS CELDAS
        worksheet.getColumn(cellMergesPoint).header = String(namesMeses[mes - 1]).toUpperCase();
        worksheet.getCell(1, cellMergesPoint).alignment = { horizontal: 'center' };
        worksheet.getCell(1, cellMergesPoint).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFA9D08E" }
        }
        /**ASIGNAMOS LAS CABECERAS DEL ROW 2 DEL EXCEL */
        worksheet.getCell(2, cellMergesPoint).value = 'EFECTIVO';
        worksheet.getCell(2, cellMergesPoint).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFCC' }
        }
        worksheet.getCell(2, nextCellMerge).value = 'ASIMILADO';
        worksheet.getCell(2, nextCellMerge).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE6B8B7' }
        }
        totalColums = [...totalColums, { width: 15 }, { width: 15 }];
        cellMergesPoint = nextCellMerge + 1;
      });
      /**ESTABLECEMOS LAS CABECERAS EN NEGRITAS */
      worksheet.getRows(1, 2)?.forEach((row) => {
        row.font = { bold: true, size: 13 };
      });

      cellMergesPoint = 4;
      /**ASIGNAMOS EL TAMAÑO DE LAS COLUMNAS */
      worksheet.columns = [{ width: 45 }, { width: 10 }, { width: 15 }, ...totalColums];
      /**ASIGAMOS LOS VALORES A LOS HEADER DE LA TABLA */
      worksheet.getCell(2, 1).value = 'COLABORADOR';
      worksheet.getCell(2, 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF00B0F0" }
      }
      worksheet.getCell(2, 1).font = {
        color: { argb: "FFFFFF" }
      }
      worksheet.getCell(2, 2).value = 'FORMA DE PAGO';
      worksheet.getCell(2, 2).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF00B0F0" }
      }
      worksheet.getCell(2, 2).font = {
        color: { argb: "FFFFFF" }
      }
      worksheet.getCell(2, 3).value = 'FECHA DE PAGO';
      worksheet.getCell(2, 3).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF00B0F0" }
      }
      worksheet.getCell(2, 3).font = {
        color: { argb: "FFFFFF" }
      }
      let START_ROW: number = 3; //FILA DONDE EMPIEZA LA INFORMACION DINAMICA
      let TOTAL_EFECTIVO: number = 0;
      let TOTAL_ASIMILADO: number = 0;
      reporte.forEach((nomina: IReporteAcumulado) => {
        worksheet.getCell(START_ROW, 1).value = nomina.colaborador;
        worksheet.getCell(START_ROW, 1).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'F000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        worksheet.getCell(START_ROW, 2).value = nomina.formaPago;
        worksheet.getCell(START_ROW, 2).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'F000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        worksheet.getCell(START_ROW, 3).value = new Date(nomina.fechaPago).toLocaleDateString();
        worksheet.getCell(START_ROW, 3).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'F000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };

        nomina.totalMes?.forEach((totales) => {
          const TotalEFE = totales.totalEfectivo;
          const TotalASIM = totales.totalAsimilado;
          worksheet.getCell(START_ROW, cellMergesPoint).value = TotalEFE;
          worksheet.getCell(START_ROW, cellMergesPoint).numFmt = '$#,##0.00';
          worksheet.getCell(START_ROW, cellMergesPoint).alignment = { horizontal: "left" }
          worksheet.getCell(START_ROW, cellMergesPoint).font = { size: 12 };
          worksheet.getCell(START_ROW, cellMergesPoint).border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'F000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          }
          worksheet.getCell(START_ROW, (cellMergesPoint + 1)).value = TotalASIM;
          worksheet.getCell(START_ROW, (cellMergesPoint + 1)).numFmt = '$#,##0.00';
          worksheet.getCell(START_ROW, (cellMergesPoint + 1)).alignment = { horizontal: "left" }
          worksheet.getCell(START_ROW, (cellMergesPoint + 1)).font = { size: 12 };
          worksheet.getCell(START_ROW, (cellMergesPoint + 1)).border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
          }
          cellMergesPoint += 2;
          TOTAL_EFECTIVO += TotalEFE;//ASIGNAMOS EL TOTAL EFECTIVO
          TOTAL_ASIMILADO += TotalASIM;//ASIGNAMOS EL TOTAL ASIM
          totalColaboradorMensual = totalColaboradorMensual
            .map((mes) => mes.mes === totales.mes ?
              {
                ...mes,
                EFECTIVO: mes.EFECTIVO + TotalEFE,
                ASIMILADO: mes.ASIMILADO + TotalASIM
              } : mes);
        });
        cellMergesPoint = 4;
        START_ROW++;
      });
      /**ASIGNAMOS LOS TOTALES X MES */
      totalColaboradorMensual.forEach((mes) => {
        worksheet.getCell(START_ROW, cellMergesPoint).value = mes.EFECTIVO;
        worksheet.getCell(START_ROW, cellMergesPoint).numFmt = '$#,##0.00';
        worksheet.getCell(START_ROW, cellMergesPoint).alignment = { horizontal: 'center' };
        worksheet.getCell(START_ROW, cellMergesPoint).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFCC' }
        };
        worksheet.getCell(START_ROW, (cellMergesPoint + 1)).value = mes.ASIMILADO;
        worksheet.getCell(START_ROW, (cellMergesPoint + 1)).numFmt = '$#,##0.00';
        worksheet.getCell(START_ROW, (cellMergesPoint + 1)).alignment = { horizontal: 'center' };
        worksheet.getCell(START_ROW, (cellMergesPoint + 1)).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE6B8B7' }
        };
        cellMergesPoint += 2;
      });
      cellMergesPoint = 4;
      START_ROW += 3;
      worksheet.mergeCells(START_ROW, 3, START_ROW, 4);//COMBINAMOS DOS CELDAS
      worksheet.getCell(START_ROW, cellMergesPoint).value = 'TOTALES';
      worksheet.getCell(START_ROW, cellMergesPoint).alignment = { horizontal: 'center' };
      worksheet.getCell(START_ROW, cellMergesPoint).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF00B0F0" }
      }
      /**ASIGNAMOS EL TOTAL EN EFECTIVO */
      START_ROW += 1;
      worksheet.getCell(START_ROW, cellMergesPoint).value = 'EFECTIVO';
      worksheet.getCell(START_ROW, cellMergesPoint).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFCC' }
      };
      worksheet.getCell(START_ROW, (cellMergesPoint + 1)).value = TOTAL_EFECTIVO;
      worksheet.getCell(START_ROW, (cellMergesPoint + 1)).numFmt = '$#,##0.00';
      /**ASIGNAMOS EL TOTAL EN ASIMILADO */
      START_ROW += 1;
      worksheet.getCell(START_ROW, cellMergesPoint).value = 'ASIMILADO';
      worksheet.getCell(START_ROW, cellMergesPoint).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE6B8B7' }
      }
      worksheet.getCell(START_ROW, (cellMergesPoint + 1)).value = TOTAL_ASIMILADO;
      worksheet.getCell(START_ROW, (cellMergesPoint + 1)).numFmt = '$#,##0.00';

      await workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Reporte Acumulado Nomina.xlsx`);
      });
      return true;
    } catch (err: any) {
      console.log(err)
      return false;
    }
  }
  const generarExcelComisiones = async (reporte: IReporteComisiones[]): Promise<boolean> => {
    try {

      const iva: number = reporte[0].iva!;
      const porcentajeFacturacion: number = reporte[0].porcentajeFacturacion!;
      const periodo: string = reporte[0].periodo!;

      const workbook: Workbook = new Workbook();
      workbook.creator = "CROSSMOTION LOGISTICS S.A DE C.V"
      workbook.created = new Date();
      const workSheet: Worksheet = workbook.addWorksheet(`COMISIONES - ${periodo}`);
      //Establecemos las columnas del Excel
      workSheet.columns = [
        { header: "Nombre", key: "colaborador", width: 50 },
        { header: "Cuenta Santander", key: "cuenta", width: 30 },
        { header: "CLABE", key: "clabe", width: 30 },
        { header: "RFC", key: "rfc", width: 20 },
        { header: "Total", key: "total_A_Pagar", width: 30 }
      ];

      let subtotalAcumulado: number = 0;
      reporte.forEach((item) => {
        workSheet.addRow({ ...item, total_A_Pagar: ajustarDecimales(item.total_A_Pagar) });
        subtotalAcumulado += item.total_A_Pagar;
      });

      //Add subtotal
      const totalAPagar: number = subtotalAcumulado + (subtotalAcumulado * (porcentajeFacturacion / 100));
      const IVA: number = (totalAPagar * (iva / 100));
      workSheet.addRow({ rfc: "Total a pagar:", total_A_Pagar: ajustarDecimales(subtotalAcumulado) }).font = { bold: true };
      workSheet.addRow({ rfc: `Subtotal (+ ${porcentajeFacturacion} %):`, total_A_Pagar: ajustarDecimales(totalAPagar) }).font = { bold: true };
      workSheet.addRow({ rfc: `IVA ${iva}%:`, total_A_Pagar: ajustarDecimales(IVA) }).font = { bold: true };
      workSheet.addRow({ rfc: "Total facturado:", total_A_Pagar: ajustarDecimales(totalAPagar + (IVA)) }).font = { bold: true };
      workSheet.eachRow((Row) => {
        Row.eachCell((Cell) => {
          Cell.alignment = {
            vertical: 'middle',
            horizontal: 'left'
          }
        });
      });

      workSheet.getRow(1).font = { bold: true };
      await workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `ASIMILADOS - ${periodo}.xlsx`);
      });
      return true;
    } catch (err: any) {
      return false;
    }
  }
  const nominasValidas = (nomina: IRemoteNomina) => {
    const nominasValidas = ["EFE", "NOM", "BECA"];
    return nominasValidas.includes(nomina.formaPago);
  }
  const compareMonths = (a: any, b: any): number => {
    return a - b;
  }
  /**PUBLIC */
  return {
    asimilados: async (periodo: string): Promise<boolean> => {
      const asimiladosResponse = await getDataAsync(`${URL_API_TH}reportes/asimiladosNomina?periodo=${periodo}`, headers);
      const porcentajeReponse = await getDataAsync(`${URL_API_TH}catalogos/porcentajeFacturacion`, headers);
      if (!asimiladosResponse.result || !porcentajeReponse.result) {
        return false;
      }
      const nominas: IReporteAsimilado[] = asimiladosResponse.data;
      const porcentajeValue: number = porcentajeReponse.data;
      return await generarExcelAsimilado(nominas, porcentajeValue);
    },
    billetiza: async (nominas: IRemoteNomina[]): Promise<boolean> => {
      const nominasEfectivo: IRemoteNomina[] = nominas.filter(nominasValidas)
        .filter((nomina: IRemoteNomina) => nomina.total_a_pagar > 0);

      if (nominasEfectivo.length === 0) return false;

      return await generarExcelEfectivo(nominasEfectivo);
    },
    recibosEfectivo: async (nominas: IRemoteNomina[]): Promise<boolean> => {

      const nominasEfectivo = nominas.filter(nominasValidas);

      if (nominasEfectivo.length === 0) return false;

      const listNominas: IRemoteNomina[] = nominasEfectivo.map((nomina) => {
        return { ...nomina, formaPago: "EFECTIVO" };
      });
      return await generarExcelRecibos(listNominas);
    },
    getReportAcumulados: async (filters: IRemoteFilterReporteAcumulado): Promise<IResponse> => {
      const filtersJSONString = JSON.stringify(filters);
      return await getDataAsync(`${URL_API_TH}reportes/acumuladosNomina?filtros=${filtersJSONString}`, headers);
    },
    acumulado: async (reporte: IReporteAcumulado[], filters: IFilterReporteAcumulado): Promise<boolean> => {
      const { numeroMes } = filters;
      return await generarExcelAcumulado(reporte, numeroMes);
    },
    getReportComisiones: async (filters: IFilterReporteComisiones): Promise<IResponse> => {
      const { anio, mes } = filters;
      return getDataAsync(`${URL_API_COMISIONES}reportes/pagadas?mes=${mes}&anio=${anio}`, headers);
    },
    comisionesPagadas: async (reporte: IReporteComisiones[]): Promise<boolean> => {
      return await generarExcelComisiones(reporte);
    }
  }
}