import { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "components/Index";
import { ColumsSubtareasSolicitudPago } from "functions/Tareas";
import DataGrid, { Column, Pager } from "devextreme-react/data-grid";

const pageSizes = [5, 10];

const GridSubtareas = ({ subTareas, handleEditSubtarea, deleteSubtarea, handleArchivosTarea}) => {

  const handleOnClickEdit = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-edit"}
        onClick={() => handleEditSubtarea(data.data)}
      />
    );
  };

  const handleOnClickDelete = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-trash"}
        onClick={() => deleteSubtarea(data.data.Tarea_Id)}
      />
    );
  };

  const handleOnClickArchivos = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-file"}
        onClick={() => handleArchivosTarea(data.data.Tarea_Id)}
      />
    );
  }

  return (
      <DataGrid
        dataSource={subTareas}
        noDataText="No se encontraron subtareas"
        showBorders={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        columnResizingMode="widget"
        columnMinWidth={40}
        scrolling={{
          columnRenderingMode: "virtual",
        }}
        paging={{ enabled: true }}
        headerFilter={{ visible: true, allowSearch: true }}
        searchPanel={{
          visible: true,
          highlightCaseSensitive: false,
          placeholder: "Buscar",
          width: 250,
        }}
        groupPanel={{
          visible: true,
          emptyPanelText: "Arrastra aqui 👇 la columna a agrupar",
        }}
        grouping={{
          texts: { groupContinuesMessage: "Continua en la siguiente página" },
          autoExpandAll: true,
        }}
        showRowLines={true}
      >
        {ColumsSubtareasSolicitudPago.map((column, index) => (
          <Column {...column} key={index} />
        ))}
        <Column caption="Archivos" alignment="center" cellComponent={handleOnClickArchivos} width={90} />
        <Column caption="Editar" alignment="center" cellComponent={handleOnClickEdit} width={90} />
        <Column caption="Eliminar" alignment="center" cellComponent={handleOnClickDelete} width={90}/>
        <Pager
          visible={true}
          allowedPageSizes={pageSizes}
          showPageSizeSelector={true}
        />
      </DataGrid>
);
};

GridSubtareas.propTypes = {
  subTareas: PropTypes.array,
  keyStateStoraged: PropTypes.string,
  onStartEditing: PropTypes.func,
  isSynchronizing: PropTypes.bool,
};

export default memo(GridSubtareas);
