import { useAuth } from 'api/auth';
import { useNominaPagada } from 'api/nomina';
import { TableGrid, Button } from 'components/Index';
import { ROLES } from 'consts/TH';
import { IColumnProps } from 'devextreme-react/data-grid';
import Loading from 'pages/Loading';
import { useEffect, useState } from 'react';

interface IProps {
  columsGrid?: IColumnProps[];
}

const GridNominasPagadas = ({ columsGrid }: IProps) => {

  const { nominas, loading, onHandlerReopenNomina } = useNominaPagada();
  const { User } = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const rol: ROLES = User.rol as ROLES;
    if (rol === "SuperAdmin") {
      setIsAdmin(true);
    }
  }, [User])

  if (loading) {
    return <Loading />
  }

  return (
    <div className='container-grid'>
      <TableGrid
        dataSource={nominas}
        columns={columsGrid}
        enableHeaderFilter={true}
        enableGroupPanel={true}
        enableSearchPanel={true}
        enableHoverState={true}
        showRowLines={true}
        enableColumnChooser={true}
        enableSessionStorage={{ enabled: true, keyStorage: 'gridNominasPagadas' }}
        enableEditing={{ modeEdit: 'cell', showIcons: true, enableUpdating: true }}
      />
      <div className='container-buttons'>
        <Button
          icon='fas fa-lock-open'
          text='Reabrir Nómina'
          width={250}
          onClick={() => isAdmin && onHandlerReopenNomina()}
          iconHover={isAdmin ? "Claro" : undefined}
          style={{
            backgroundColor: isAdmin ? '#007bff' : '#808080',
            color: 'white',
            cursor: isAdmin ? 'pointer' : 'not-allowed'
          }}
          disabled={nominas.length === 0 || !isAdmin}
        />
      </div>
    </div>
  )
}

export default GridNominasPagadas