import { useMemo, useCallback } from "react";
import "./styles/estilos.scss";
import { useTask } from "contexts/TaskContext";
import GridTareas from "./components/GridTareas";
import ModalActualizarTarea from "./components/ModalActualizarTarea";
import ModalArchivos from "./components/ModalArchivos";
import MenuFilters from "./components/MenuFilters";
import CreateTaskForm from "./components/CreateTaskForm";
import { ITarea } from "interfaces/ITareas";

const Tareas = () => {
  const {
    tarea,
    dataTareas,
    tabVisible,
    modalStatus,
    updateTarea,
    deleteTarea,
    responsables,
    entidadDefined,
    setModalStatus,
    handleEditTarea,
    handlerOnSelectTab,
    modalArchivos,
    setModalArchivos,
    dataArchivos,
    handleArchivosTarea,
    uploadFiles,
    addArchivosTarea,
    deleteArchivoTarea,
    setDataArchivos,
    handleSubTareas
  } = useTask();

  const memoizedDataTareas = useMemo(() => dataTareas.data, [dataTareas.data]);

  const memoizedDeleteTarea = useCallback((id: number) => {
    deleteTarea(id);
  }, [deleteTarea]);

  const memoizedHandleEditTarea = useCallback((tarea: ITarea) => {
    handleEditTarea(tarea);
  }, [handleEditTarea]);

  return ( 
<>
    {dataTareas.loading ? (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="icon loading-icon loading-size-lg"></div>
      </div>
    ) : (
      <div className="container-tareas">
        <br />
        <MenuFilters>
          <CreateTaskForm 
          isPaymentType={false}
          />
        </MenuFilters>
        <GridTareas
          isPaymentType={false}
          deleteTarea={memoizedDeleteTarea}
          dataTareas={memoizedDataTareas}
          handleEditTarea={memoizedHandleEditTarea}
          handleArchivosTarea={handleArchivosTarea}
          handleSubTareas={handleSubTareas}
        />
        {modalStatus && (
          <ModalActualizarTarea
            tarea={tarea}
            tabVisible={tabVisible}
            statusModal={modalStatus}
            updateTarea={updateTarea}
            entidades={entidadDefined.data}
            responsables={responsables.data}
            handlerOnSelectTab={handlerOnSelectTab}
            closePopup={() => setModalStatus(!modalStatus)}
          />
        )}
        {modalArchivos && (
          <ModalArchivos
            modalArchivos={modalArchivos}
            archivos={dataArchivos}
            setArchivos={setDataArchivos}
            uploadFiles={uploadFiles}
            addFiles={addArchivosTarea}
            deleteFile={deleteArchivoTarea}
            closePopup={() => setModalArchivos(!modalArchivos)}
          />
        )}
      </div>
    )}
  </>
  );
};

export default Tareas;
