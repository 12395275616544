import React, { useEffect, useState } from 'react';
import { useShipments } from './hooks/useShipments';
import GridEmbarques from './componentes/GridEmbarques';
import { Button, Select } from 'components/Index';
import { useTask } from 'contexts/TaskContext';
import { Popup } from 'devextreme-react';
import axios from 'axios';
import { URL_API_FIREBASE_APP } from 'consts/Services_URL';

const DesgloseServiciosEmbarques = () => {

  const { dataEmbarques } = useShipments();
  const { 
    data,
    setData,
    onHandlerSync,
    loading
  } = useTask();

  const [openModal, setOpenModal] = useState(false);
  const [tipo, setTipo] = useState<string | null>(null);
  const [guids, setGuids] = useState('');
  const [keepAlive, setKeepAlive] = useState(false);

  useEffect(() => {
    const fetchKeepAlive = async () => {
      try {
        const response = await axios.get(
          `${URL_API_FIREBASE_APP}/keepAlive`
        );

        if (response.status !== 200) {
          setKeepAlive(false);
          return;
        }
        setKeepAlive(true);
      } catch (error) {
        setKeepAlive(false);
      }
    };
    fetchKeepAlive();
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <Popup
        visible={openModal}
        onHiding={() => setOpenModal(false)}
        title="Agregar GUID o Job Number"
        width={300}
        height={400}
        contentRender={() => (
          <>
            <Select 
              title="Tipo"
              item={{
                key: 'Sync',
                title: 'Sync',
                items: [
                  "Embarque",
                  "Cliente",
                  "Proveedor",
                  "Facturas de Pago",
                  "Facturas de Cobro",
                  "Cheques",
                  "Pagos",
                  "Cotizaciones"
                ]
              }}
              onChangeSelect={(e) => {
                setTipo(e.value);
              }}
            />
            <textarea 
              className="textarea"
              placeholder="GUIDs"
              style={{ width: '100%', marginTop: 10, height: 200 }}
              value={guids}
              onChange={(e) => setGuids(e.target.value)}
            />
            <Button
              disabled={loading}
              icon={`fas fa-${loading ? 'spin' : 'sync-alt'}`}
              text='Agregar GUIDs'
              width={250}
              iconHover="Claro"
              style={{ backgroundColor: "#10A800" }}
              onClick={async () => {
                const guidsArray = guids.split('\n').filter(Boolean);

                if (!tipo || !guidsArray.length) return;

                const uniqueGuids = Array.from(new Set(guidsArray));
                const newGuids = uniqueGuids.filter(
                  guid => !data.some(item => item.reporte_Sync_Guid === guid)
                );

                const syncData = newGuids.map(guid => ({
                  reporte_Sync_Tipo: tipo,
                  reporte_Sync_Guid: guid,
                  isDeleted: false,
                }));

                setData((prevData) => [...prevData, ...syncData]);
                await onHandlerSync(syncData);
                setOpenModal(false);
                setGuids('');
              }}
            />
          </>
        )}
      />
      {
        dataEmbarques.loading ? 
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '100px' }}>
            <div className="icon loading-icon loading-size-lg"></div>
          </div>
        :
        <div className="container-configuracionModules">   
          <div className='container-buttons' style={{ justifyContent: 'right' }}>
            <Button
              icon='fas fa-sync'
              disabled={!keepAlive}
              width={'5%'}
              iconHover='Claro'
              style={{ backgroundColor: "#006BD5", marginTop: '10px', marginBottom: '10px' }}
              onClick={() => setOpenModal(true)}
              text={!keepAlive ? 'Servicio no disponible' : '' }
            />        
          </div>
          <GridEmbarques
            dataEmbarques={dataEmbarques.data}
          />
        </div>
      }
    </div>
  );
};

export default DesgloseServiciosEmbarques;