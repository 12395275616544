import { useState,useEffect } from "react";
import { KEY_LOCALSTORAGE_USER } from "consts/User";
import moment from "moment";
import "../styles/estilos.scss";
import { Form } from "devextreme-react";
import { ButtonItem, GroupItem, SimpleItem } from "devextreme-react/form";

const FormEditarTarea = ({
  tarea,
  entidades,
  closePopup,
  updateTarea,
  responsables
}) => {
  const [notIsAdmin, setNotIsAdmin] = useState(false);

  useEffect(() => {
      const userState = localStorage.getItem(KEY_LOCALSTORAGE_USER);
      if (userState) {
        const { guid } = JSON.parse(userState);

        const responsable = responsables.find((responsable) => responsable.guid === guid);
        if (responsable) {
          setNotIsAdmin(false);
        } else if (tarea.Tarea_Nombre === "SOLICITUD DE PAGO") {
          setNotIsAdmin(false);
        } else {
          setNotIsAdmin(true);
        }
      }
  },[responsables, tarea.Tarea_Nombre]);

  const nombreTareaItems = [
    { Nombre_Tarea_Id: 1, Tarea_Nombre: "LLAMADA" },
    { Nombre_Tarea_Id: 2, Tarea_Nombre: "CORREO" },
    { Nombre_Tarea_Id: 3, Tarea_Nombre: "COBRANZA" },
    { Nombre_Tarea_Id: 4, Tarea_Nombre: "WHATSAPP" },
    { Nombre_Tarea_Id: 5, Tarea_Nombre: "ENVIO ESTADO DE CUENTA" },
    { Nombre_Tarea_Id: 6, Tarea_Nombre: "REVISION DE PORTAL" },
    { Nombre_Tarea_Id: 7, Tarea_Nombre: "SOLICITUD DE PAGO" },
    { Nombre_Tarea_Id: 8, Tarea_Nombre: "OTRO" },
  ];
  const urgenciaItems = [
    { Urgencia_Id: 1, urgencia: "Baja" },
    { Urgencia_Id: 2, urgencia: "Media" },
    { Urgencia_Id: 3, urgencia: "Alta" },
  ];
  const estatusItems = [
    { Estatus_Id: 1, estatus: "Pendiente" },
    { Estatus_Id: 2, estatus: "En Proceso" },
    { Estatus_Id: 3, estatus: "Terminada" },
  ];

  const serviciosItems = [
    { Servicio_Id: 1, servicio: "Ocean" },
    { Servicio_Id: 2, servicio: "Air" },
    { Servicio_Id: 3, servicio: "Ground" },
    { Servicio_Id: 4, servicio: "Gastos" },
  ];

  const monedasItems = [
    { Moneda_Id: 1, moneda: "MXN" },
    { Moneda_Id: 2, moneda: "USD" },
    { Moneda_Id: 3, moneda: "EUR" },
  ];

  const cancelPopup = () => {
    closePopup();
  };

  const optionsComponents = {
    nombreTareaOptions: {
      searchEnabled: true,
      items: nombreTareaItems || [],
      displayExpr: "Tarea_Nombre",
      valueExpr: "Nombre_Tarea_Id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información"
    },
    responsablesOptions: {
      searchEnabled: true,
      items: responsables || [],
      displayExpr: "colaborador",
      valueExpr: "colaborador_id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
    },
    entidadesOptions: {
      searchEnabled: true,
      items: entidades || [],
      valueExpr: "Entidad_Id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
      displayExpr: "Entidad_Razon_Social",
    },
    serviciosOptions: {
      searchEnabled: true,
      items: serviciosItems || [],
      valueExpr: "Servicio_Id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
      displayExpr: "servicio",
    },
    monedasOptions: {
      searchEnabled: true,
      items: monedasItems || [],
      valueExpr: "Moneda_Id",
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
      displayExpr: "moneda",
    },
    urgenciaOptions: {
      searchEnabled: true,
      displayExpr: "urgencia",
      valueExpr: "Urgencia_Id",
      items: urgenciaItems || [],
      placeholder: "--Selecciona--",
      noDataText: "No hay información",
    },
    estatusOptions: {
      searchEnabled: true,
      displayExpr: "estatus",
      valueExpr: "Estatus_Id",
      items: estatusItems || [],
      placeholder: "--Selecciona--",
      noDataText: "No hay información"
    },
    formatFecha: {
      format: "dd/MM/yyyy",
    },
    btnCloseOptions: {
      type: "normal",
      width: "250px",
      text: "CERRAR",
      onClick: () => cancelPopup(),
    },
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    const nota = tarea.Tarea_Nota;
    const tareaId = tarea.Tarea_Id;
    const entidadId = tarea.Entidad_Id;
    const estatusId = tarea.Estatus_Id;
    const urgenciaId = tarea.Urgencia_Id;
    const responsableId = tarea.colaborador_id;
    const fechaTermino = tarea.Tarea_Fecha_Termino;
    const fechaActualizacion = moment().utc().format("YYYY-MM-DD 00:00:00");
    const nombreTareaId = tarea.Nombre_Tarea_Id;
    const servicioId = tarea.Servicio_Id;
    const monedaId = tarea.Moneda_Id;
    const monto = tarea.Tarea_Monto;

    const getGuidResponsable = (colaboradorId, responsables) => {
      const responsable = responsables.find(
        (r) => r.colaborador_id === colaboradorId
      );
      return responsable ? responsable.guid : null;
    };

    const getGuidEntidad = (entidadId, entidades) => {
      const entidad = entidades.find((e) => e.Entidad_Id === entidadId);
      return entidad ? entidad.Entidad_Guid : null;
    };

    const getUrgencia = (urgenciaId, urgenciaItems) => {
      const urgencia = urgenciaItems.find((u) => u.Urgencia_Id === urgenciaId);
      return urgencia ? urgencia.urgencia : null;
    };

    const getEstatus = (estatusId, estatusItems) => {
      const estatus = estatusItems.find((e) => e.Estatus_Id === estatusId);
      return estatus ? estatus.estatus : null;
    };

    const getNombreTarea = (nombreTareaId, nombreTareaItems) => {
      const nombreTarea = nombreTareaItems.find((e) => e.Nombre_Tarea_Id === nombreTareaId);
      return nombreTarea ? nombreTarea.Tarea_Nombre : null;
    };

    const getServicio = (servicioId, serviciosItems) => {
      const servicio = serviciosItems.find((s) => s.Servicio_Id === servicioId);
      return servicio ? servicio.servicio : null;
    };

    const getMoneda = (monedaId, monedasItems) => {
      const moneda = monedasItems.find((m) => m.Moneda_Id === monedaId);
      return moneda ? moneda.moneda : null;
    };

    const guidResponsable = getGuidResponsable(responsableId, responsables);
    const guidEntidad = getGuidEntidad(entidadId, entidades);
    const urgencia = getUrgencia(urgenciaId, urgenciaItems);
    const estatus = getEstatus(estatusId, estatusItems);
    const nombreTarea = getNombreTarea(nombreTareaId, nombreTareaItems);
    const servicio = getServicio(servicioId, serviciosItems);
    const moneda = getMoneda(monedaId, monedasItems);

    const tareaUpdated = {
      Tarea_Nota: nota,
      Estatus: estatus,
      Tarea_Id: tareaId,
      Urgencia: urgencia,
      Tarea_Nombre: nombreTarea,
      Entidad_Guid: guidEntidad,
      Tarea_Fecha_Termino: fechaTermino,
      Colaborador_Guid: guidResponsable,
      Tarea_Fecha_Actualizacion: fechaActualizacion,
      Tarea_Tipo_Servicio: servicio,
      Tarea_Monto: monto,
      Tarea_Moneda: moneda,
    };
    updateTarea(tareaUpdated);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Form
          key="id"
          formData={tarea}
          scrollByContent={true}
          scrollingEnabled={true}
          showValidationSummary={true}
          colCountByScreen={colCountByScreen}
        >
          <GroupItem cssClass="form-group" colCount={1}>
            <GroupItem colSpan={1}>
              <SimpleItem
                editorType="dxSelectBox"
                dataField="Nombre_Tarea_Id"
                editorOptions={optionsComponents.nombreTareaOptions}
              />
              <SimpleItem
                editorType="dxSelectBox"
                dataField="colaborador_id"
                editorOptions={optionsComponents.responsablesOptions}
              />
              <SimpleItem
                dataField="Entidad_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.entidadesOptions}
              />
              <SimpleItem
                dataField="Servicio_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.serviciosOptions}
              />
              <SimpleItem
                dataField="Tarea_Monto"
                editorType="dxNumberBox"
                editorOptions={{ format: "#,##0.00" }}
              />
              <SimpleItem
                dataField="Moneda_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.monedasOptions}
              />
            </GroupItem>

            <GroupItem colSpan={1}>
              <SimpleItem
                dataField="Urgencia_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.urgenciaOptions}
              />
              <SimpleItem
                dataField="Estatus_Id"
                editorType="dxSelectBox"
                editorOptions={optionsComponents.estatusOptions}
              />
              <SimpleItem
                editorType="dxDateBox"
                dataField="Tarea_Fecha_Termino"
                editorOptions={optionsComponents.formatFecha}
              />
            </GroupItem>
          </GroupItem>
          <GroupItem colCount={1}>
            <SimpleItem
              colSpan={1}
              dataField="Tarea_Nota"
              editorType="dxTextArea"
            />
          </GroupItem>
          <GroupItem colCount={2} cssClass={"btnToolbar-group"}>
            <ButtonItem
              verticalAlignment="bottom"
              horizontalAlignment="center"
              cssClass={"btnToolbar-group"}
              buttonOptions={{
                width: "250px",
                text: "GUARDAR",
                type: "default",
                useSubmitBehavior: true,
                disabled: notIsAdmin
              }}
            />
            <ButtonItem
              verticalAlignment="bottom"
              horizontalAlignment="center"
              buttonOptions={optionsComponents.btnCloseOptions}
            />
          </GroupItem>
        </Form>
      </form>
    </div>
  );
};

const colCountByScreen = {
  sm: 1,
  md: 1,
  lg: 1,
};

export default FormEditarTarea;
