import { IColumnProps } from "devextreme-react/data-grid";

export interface ITareas{
    Tarea_Id: number;
    Tarea_Nombre: string;
    Tarea_Estatus: string;
    Tarea_Urgencia: string;
    Tarea_Nota: string;
    Tarea_Fecha_Creacion: Date;
    Tarea_Fecha_Llamada: Date;
    Tarea_Fecha_Termino: Date;
    Tarea_Fecha_Ultima_Actualizacion: Date;
    Tarea_Monto?: number;
    Tarea_Moneda?: string;
    Tarea_Mes?: string;
    Tarea_Semana?: string;
    Entidad_Razon_Social: string;
    Entidad_Guid: string;
    cMotion_Perfil_Colaborador_Usuario_cMotion: string;
    cMotion_Perfil_Colaborador_Nombre: string;
    cMotion_Perfil_Colaborador_Apellido_Paterno: string;
    cMotion_Perfil_Colaborador_Apellido_Materno: string;
    colaborador_id: number;
    Tarea_Tipo_Servicio: string;
}

export interface IGetDataTareas {
    data?: ITareas[];
    error?: string | null;
    loading: boolean;
}

export interface IGetDataSubtareas {
    data?: ITareas[];
    error?: string | null;
    loading: boolean;
    Tarea_Padre_Id: number;
    Entidad_Guid: string;
    Tarea_Padre: ITarea;
}


export interface IResponsables {
    cMotion_Perfil_Colaborador_Apellido_Materno: string;
    cMotion_Perfil_Colaborador_Apellido_Paterno: string;
    cMotion_Perfil_Colaborador_Guid: string;
    cMotion_Perfil_Colaborador_Nombre: string;
    colaborador_id: number;
}

export interface IResponsablesFormated {
    colaborador: string;
    guid: string;
    colaborador_id: number;
}

export interface IGetDataResponsables {
    data?: IResponsablesFormated[];
    error?: string | null;
    loading: boolean;
}

export interface IEntidades{
    Entidad_Razon_Social: string;
    Entidad_Id: number;
    Entidad_Guid: string;
}

export interface IGetDataEntidades {
    data?: IEntidades[];
    error?: string | null;
    loading: boolean;
}

export interface IAddTarea {
    Tarea_Nombre: string;
    Tarea_Urgencia: string;
    Tarea_Entidad_Cliente_Guid: string;
    Tarea_Colaborador_Guid: string;
    Tarea_Fecha_Termino: Date;
    Tarea_Nota: string;
    Tarea_Estatus: string;
    Tarea_Tipo_Servicio: string;
}

export interface ITarea{
    Tarea_Id: number;
    Tarea_Nombre: string;
    Tarea_Estatus: string;
    Tarea_Urgencia: string;
    Tarea_Nota: string;
    Tarea_Fecha_Creacion: Date;
    Tarea_Fecha_Llamada: Date;
    Tarea_Fecha_Termino: Date;
    Tarea_Fecha_Ultima_Actualizacion: Date;
    Tarea_Tipo_Servicio: string;
    Entidad_Razon_Social: string;
    Entidad_Tipo: string;
    cMotion_Perfil_Colaborador_Apellido_Materno: string;
    cMotion_Perfil_Colaborador_Apellido_Paterno: string;
    cMotion_Perfil_Colaborador_Nombre: string;
    Urgencia_Id: number;
    Estatus_Id: number;
    Nombre_Tarea_Id: number;
    Servicio_Id: number;
    Moneda_Id: number;
    Tarea_Moneda: string;
    Tarea_Monto: number;
}

export interface IUpdateTarea {
    Tarea_Id: number;
    Tarea_Nombre: string;
    Tarea_Nota: string;
    Urgencia: string;
    Estatus: string;
    Entidad_Guid: string;
    Tarea_Monto?: number;
    Tarea_Moneda?: string;
    Tarea_Fecha_Actualizacion: string;
    Entidad_Razon_Social?: string;
    Tarea_Fecha_Termino?: Date;
    Colaborador_Guid?: string;
}

export interface IActividadesTarea {
    Tarea_Detalle_Id: number;
    Tarea_Id: number;
    Tarea_Detalle_Fecha_Actividad: Date;
    Tarea_Detalle_Nota: string;
    Tarea_Detalle_Colaborador_Guid: string;
    Tarea_Nombre: string;
    cMotion_Perfil_Colaborador_Nombre: string;
    cMotion_Perfil_Colaborador_Apellido_Paterno: string;
    cMotion_Perfil_Colaborador_Apellido_Materno: string;
    Tarea_Detalle_Responsable: string;
}

export interface ISeguimientoEntidades {
    Entidad_Razon_Social: string;
    Entidad_Guid: string;
    Entidad_Ejecutivo_Admin: string;
    Documento_Contable_Fecha_Creacion: Date;
    Fecha_Arribo: Date;
    Total_Deuda_MXN: Number;
    Cantidad_Pagada_MXN: Number;
    A_Pagar: Date;
    Dias_Vencidos: Number;
    Tarea_Nombre: string;
    Tarea_Nota: string;
}

export interface IPostArchivo {
    nombre: string;
    url: string;
}

export interface IAddArchivosTarea {
    Tarea_Id: number;
    Archivos: IPostArchivo[];
}

export interface IPostArchivo {
    nombre: string;
    url: string;
}

export interface IAddArchivosTarea {
    Tarea_Id: number;
    Archivos: IPostArchivo[];
}

export interface IGetDataSeguimientoClientes {
    data?: ISeguimientoEntidades[];
    error?: string | null;
    loading: boolean;
}

export interface IGetDataSeguimientoProveedores {
    data?: ISeguimientoEntidades[];
    error?: string | null;
    loading: boolean;
}

export interface ISeguimientoTareas{
    Tarea_Id: number;
    Tarea_Nombre: string;
    Tarea_Estatus: string;
    Tarea_Urgencia: string;
    Tarea_Fecha_Termino: Date;
    Tarea_Nota: string;
    Tarea_Fecha_Ultima_Actualizacion: Date;
    Tarea_Fecha_Creacion: Date;
    Entidad_Razon_Social: string;
    Entidad_Guid: string;
    cMotion_Perfil_Colaborador_Usuario_cMotion: string;
    Nombre_Colaborador: string;
}

export interface IGetTareasEntidad {
    data?: ISeguimientoTareas[];
    error?: string | null;
    loading: boolean;
}

interface ISelects {
    id: number;
    name: string;
  }

const tiposUrgencias: ISelects[] = [
    {
      id: 1,
      name: "Baja",
    },
    {
      id: 2,
      name: "Media",
    },
    {
      id: 3,
      name: "Alta",
    },
];

const tiposEstatus: ISelects[] = [
    {
      id: 1,
      name: "Pendiente",
    },
    {
      id: 2,
      name: "En Proceso",
    },
    {
      id: 3,
      name: "Terminada",
    },
];

export const columnsTareasEntidad = (responsables: IResponsablesFormated[]) : IColumnProps[] => {
    const columns: IColumnProps[] = [
        {
            caption: 'Nombre',
            dataField: 'Tarea_Nombre',
        },
        {
            caption: 'Descripción',
            dataField: 'Tarea_Nota',
        },
        {
            caption: "Estatus",
            dataType: "string",
            dataField: "Tarea_Estatus",
            lookup: {
              dataSource: tiposEstatus,
              displayExpr: "name",
              valueExpr: "name",
            },
        },
        {
            caption: "Urgencia",
            dataType: "string",
            dataField: "Tarea_Urgencia",
            lookup: {
              dataSource: tiposUrgencias,
              displayExpr: "name",
              valueExpr: "name",
            },
        },
        {
            caption: 'Responsable',
            dataField: 'cMotion_Perfil_Colaborador_Id',
            dataType: 'string',
            lookup: {
                dataSource: responsables,
                displayExpr: "colaborador",
                valueExpr: "colaborador_id",
            },
        },
        {
            caption: 'Fecha Creación',
            dataType: 'date',
            format: 'dd/MM/yyyy',
            dataField: 'Tarea_Fecha_Creacion',
        }
    ]
    return columns;
}