import { memo, useState, useEffect } from "react";
import { KEY_LOCALSTORAGE_USER } from "consts/User";
import { Button } from "components/Index";
import { DateBox } from "devextreme-react";
import { Autocomplete, TextField } from "@mui/material";
import { useTask } from "contexts/TaskContext";
import notify from "devextreme/ui/notify";
import { nombresTareas, tiposEntidades, tiposUrgencias, tiposEstatus, tiposServicios, tiposMonedas } from "consts/TareasTipos";
import { IEntidades } from "interfaces/ITareas";

interface CreateTaskFormProps {
  isPaymentType: boolean;
  Tarea_Padre_Id?: number;
  Entidad_Guid?: string;
  onSuccess?: () => void;
}

const CreateTaskForm = ({ isPaymentType, Tarea_Padre_Id, Entidad_Guid, onSuccess }: CreateTaskFormProps) => {
  const {
    getEntidades,
    responsables,
    addTarea,
    uploadFiles
  } = useTask();

  const [nombreTareaSeleccionado, setNombreTareaSeleccionado] = useState("");
  const [tipoEntidad, setTipoEntidad] = useState("");
  const [entidadSeleccionada, setEntidadSeleccionada] = useState("");
  const [entidades, setEntidades] = useState<IEntidades[]>([]);
  const [responsableSeleccionado, setResponsableSeleccionado] = useState("");
  const [urgenciaSeleccionada, setUrgenciaSeleccionada] = useState("Baja");
  const [estatusSeleccionado, setEstatusSeleccionado] = useState("Pendiente");
  const [tipoServicioSeleccionado, setTipoServicioSeleccionado] = useState("");
  const [fechaTermino, setFechaTermino] = useState<Date>(new Date());
  const [note, setNote] = useState("");
  const [loadingEntidades, setLoadingEntidades] = useState(false);
  const [files, setFiles] = useState<{ file: File, uploadFailed?: boolean }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [estatusDisabled, setEstatusDisabled] = useState(false);
  const [monto, setMonto] = useState("");
  const [monedaSeleccionada, setMonedaSeleccionada] = useState("");
  const [nombreTareaEstatus, setNombreTareaEstatus] = useState(false);
  const [isPaymentActive, setIsPaymentActive] = useState(false);
  const [errors, setErrors] = useState({
    Tarea_Nombre: "",
    Tipo_Entidad: "",
    Entidad: "",
    Responsable: "",
    Tipo_Servicio: "",
    Monto: "",
    Moneda: ""
  });

  useEffect(() => {
    if (isPaymentType) {
      setNombreTareaSeleccionado("SOLICITUD DE PAGO");
      // Hardcoded value for the responsable Hugo Daniel Cervantes
      setResponsableSeleccionado("848E5914-08FA-4107-8BE4-45B9A5CB33F6")
      setTipoEntidad("Vendor")
    }
  }, [isPaymentType, Entidad_Guid]);

  useEffect(() => {
    if (!isPaymentType){
      const userState = localStorage.getItem(KEY_LOCALSTORAGE_USER);
      if (userState) {
        const { guid } = JSON.parse(userState);

        const responsable = responsables.data?.find((responsable) => responsable.guid === guid);
        if (responsable) {
          setResponsableSeleccionado(responsable.guid);
        } else {
          setNombreTareaSeleccionado("SOLICITUD DE PAGO");
          setNombreTareaEstatus(true);
          setIsPaymentActive(true);
        }
      }
    }
  },[responsables.data, isPaymentType, nombreTareaSeleccionado]);

  useEffect(() => {
    if (nombreTareaSeleccionado === "SOLICITUD DE PAGO") {
      setEstatusSeleccionado("Pendiente");
      setTipoEntidad("Vendor");
      setIsPaymentActive(true);
    } else {
      setTipoEntidad("");
      setEstatusSeleccionado("Terminada");
    }
  }, [nombreTareaSeleccionado]);

  useEffect(() => {
    if (tipoEntidad) {
      setLoadingEntidades(true);
      getEntidades(tipoEntidad).then((data) => {
        setEntidades(data);
        setLoadingEntidades(false);
      });
    } else {
      setEntidades([]);
    }
  }, [tipoEntidad, getEntidades]);

  const onFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files).map(file => ({
        file: file,
        uploadFailed: false
      }));
      setFiles([...files, ...selectedFiles]);
    }
  };

  const removeFile = (fileName: string) => {
    const remainingFiles = files.filter((file) => file.file.name !== fileName);
    setFiles(remainingFiles);
  };

  const formatFileSize = (size: number) => {
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / 1024 / 1024).toFixed(2)} MB`;
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
  
    if (!nombreTareaSeleccionado) newErrors.Tarea_Nombre = "El nombre de la tarea es obligatorio";
    if (!tipoEntidad) newErrors.Tipo_Entidad = "El tipo de entidad es obligatorio";
    if (!entidadSeleccionada) newErrors.Entidad = "Debes seleccionar una entidad";
    if (!responsableSeleccionado) newErrors.Responsable = "El responsable es obligatorio";
    if ((isPaymentType && !tipoServicioSeleccionado) || (isPaymentActive && !tipoServicioSeleccionado)) newErrors.Tipo_Servicio = "El tipo de servicio es obligatorio";
    if (!fechaTermino) newErrors.Fecha_Termino = "La fecha de término es obligatoria";
    if ((isPaymentType && !monto) || (isPaymentActive && !monto)) newErrors.Monto = "El monto es obligatorio";
    if ((isPaymentType && !monedaSeleccionada) || (isPaymentActive && !monto)) newErrors.Moneda = "La moneda es obligatoria";
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const handleNombreTareaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const selectedName = e.target.value;
    setNombreTareaSeleccionado(selectedName);

    if (selectedName !== "SOLICITUD DE PAGO" && selectedName !== "OTRO") {
      setEstatusSeleccionado("Terminada");
    } else {
      setEstatusSeleccionado("Pendiente");
      setEstatusDisabled(false);
    }
  };
  
  const handlerCreateTarea = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      notify("Por favor, completa los campos obligatorios.", "error", 2000);
      return;
    }
    
    setIsLoading(true);
    const uploadedFiles = await uploadFiles(files.map(fileData => fileData.file));

    const successfulUploads = uploadedFiles.filter(file => file.success);

    const nuevaTarea = {
      Tarea_Nombre: nombreTareaSeleccionado,
      Tarea_Urgencia: urgenciaSeleccionada,
      Tarea_Entidad_Cliente_Guid: entidadSeleccionada,
      Tarea_Colaborador_Guid: responsableSeleccionado,
      Tarea_Fecha_Termino: fechaTermino,
      Tarea_Nota: note,
      Tarea_Estatus: estatusSeleccionado,
      Tarea_Tipo_Servicio: tipoServicioSeleccionado,
      Archivos: successfulUploads.map(file => ({
        nombre: file.name,
        url: file.url
      })),
      Tarea_Padre_Id: Tarea_Padre_Id || null,
      Tarea_Monto: monto,
      Tarea_Moneda: monedaSeleccionada
    };

    await addTarea(nuevaTarea);

    setNombreTareaSeleccionado("");
    setTipoEntidad("");
    setEntidadSeleccionada("");
    setResponsableSeleccionado("");
    setUrgenciaSeleccionada("Baja");
    setEstatusSeleccionado("Pendiente");
    setTipoServicioSeleccionado("");
    setFechaTermino(new Date());
    setNote("");
    setFiles([]);
    setIsLoading(false);
    notify("Tarea agregada exitosamente", "success", 2000);

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <div className="container-filters">
      <form onSubmit={handlerCreateTarea}>
        <h2>Crear Tarea</h2>
        <div className="select-container label_input_task">
          <label htmlFor="nombreTarea">Nombre de la Tarea</label>
          <select
            id="nombreTarea"
            name="nombreTarea"
            value={nombreTareaSeleccionado}
            onChange={handleNombreTareaChange}
            disabled={isPaymentType || nombreTareaEstatus}
          >
            {nombresTareas.map((nombreTarea) => (
              <option key={nombreTarea.value} value={nombreTarea.value}>
                {nombreTarea.nombre}
              </option>
            ))}
          </select>
          {errors.Tarea_Nombre && <span className="error-message">{errors.Tarea_Nombre}</span>}
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="tipoEntidad">Tipo de Entidad</label>
          <select
            id="tipoEntidad"
            name="tipoEntidad"
            value={tipoEntidad}
            onChange={(e) => setTipoEntidad(e.target.value)}
          >
            {tiposEntidades.map((entidad) => (
              <option key={entidad.value} value={entidad.value}>
                {entidad.nombre}
              </option>
            ))}
          </select>
          {errors.Tipo_Entidad && <span className="error-message">{errors.Tipo_Entidad}</span>}
        </div>
        <div className="label_input_task">
          <label htmlFor="entidadAutocomplete">Entidad</label>
          <Autocomplete
            id="entidadAutocomplete"
            size="small"
            options={entidades}
            loading={loadingEntidades}
            disabled={entidades.length === 0}
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.Entidad_Razon_Social}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option.Entidad_Guid}>
                {option.Entidad_Razon_Social}
              </li>
            )}
            value={entidades.find(
              (entidad) => entidad.Entidad_Guid === entidadSeleccionada
            ) || null
            }
            onChange={(event, newValue) =>
              setEntidadSeleccionada(newValue ? newValue.Entidad_Guid : "")
            }
          />
          {errors.Entidad && <span className="error-message">{errors.Entidad}</span>}
        </div>
        <div className="label_input_task">
          <label htmlFor="responsableAutocomplete">
            Responsable de la tarea
          </label>
          <Autocomplete
            size="small"
            options={responsables.data!}
            id="responsableAutocomplete"
            className="autocomplete_input_task"
            getOptionLabel={(option) => option.colaborador}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option.guid}>
                {option.colaborador}
              </li>
            )}
            value={responsables.data!.find(
              (responsable) => responsable.guid === responsableSeleccionado
            ) || null
            }
            onChange={(event, newValue) =>
              setResponsableSeleccionado(newValue ? newValue.guid : "")
            }
          />
          {errors.Responsable && <span className="error-message">{errors.Responsable}</span>}
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="tipoServicio">Tipo de Servicio</label>
          <select
            id="tipoServicio"
            name="tipoServicio"
            value={tipoServicioSeleccionado}
            onChange={(e) => setTipoServicioSeleccionado(e.target.value)}
          >
            {tiposServicios.map((tipoServicio) => (
              <option key={tipoServicio.value} value={tipoServicio.value}>
                {tipoServicio.nombre}
              </option>
            ))}
          </select>
          {errors.Tipo_Servicio && <span className="error-message">{errors.Tipo_Servicio}</span>}
        </div>
        {(isPaymentType || isPaymentActive) && (
        <>
          <div className="label_input_task">
            <label htmlFor="monto">Monto</label>
            <input
              type="number"
              id="monto"
              name="monto"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              placeholder="Ingresa el monto"
            />
            {errors.Monto && <span className="error-message">{errors.Monto}</span>}
          </div>
          <div className="select-container label_input_task">
          <label htmlFor="moneda">Moneda</label>
          <select
            id="moneda"
            name="moneda"
            value={monedaSeleccionada}
            onChange={(e) => setMonedaSeleccionada(e.target.value)}
          >
            {tiposMonedas.map((moneda) => (
              <option key={moneda.value} value={moneda.value}>
                {moneda.nombre}
              </option>
            ))}
          </select>
          {errors.Moneda && <span className="error-message">{errors.Moneda}</span>}
        </div>
        </>
        )}
        <div className="select-container label_input_task">
          <label htmlFor="urgencia">Prioridad</label>
          <select
            id="urgencia"
            name="urgencia"
            value={urgenciaSeleccionada}
            onChange={(e) => setUrgenciaSeleccionada(e.target.value)}
          >
            {tiposUrgencias.map((urgencia) => (
              <option key={urgencia.value} value={urgencia.value}>
                {urgencia.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="select-container label_input_task">
          <label htmlFor="estatus">Estatus</label>
          <select
            id="estatus"
            name="estatus"
            value={estatusSeleccionado}
            disabled={estatusDisabled}
            onChange={(e) => setEstatusSeleccionado(e.target.value)}
          >
            {tiposEstatus.map((estatus) => (
              <option key={estatus.value} value={estatus.value}>
                {estatus.nombre}
              </option>
            ))}
          </select>

        </div>
        <div className="label_input_task">
          <label htmlFor="Tarea_Fecha_Termino">Fecha Termino</label>
          <DateBox
            type="date"
            value={fechaTermino}
            pickerType="calendar"
            name="Tarea_Fecha_Termino"
            className="date_input_task"
            onValueChanged={(e) => setFechaTermino(e.value)}
          />
        </div>
        <div className="label_input_task">
          <label htmlFor="note">Nota</label>
          <input
            type="text"
            name="note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
        <div className="file-uploader">
          <label className="select-file-button">
            Subir Archivo
            <i className="fas fa-upload" style={{marginLeft: "10px"}}></i>
            <input type="file" multiple onChange={onFilesSelected} hidden />
          </label>
          {files.map((fileData, index) => (
            <div key={index} className="file-info">
              {fileData.file.name} - {formatFileSize(fileData.file.size)}
              <span className="delete-file-button" onClick={() => removeFile(fileData.file.name)}>×</span>
              {fileData.uploadFailed && <span className="upload-status">Upload failed</span>}
            </div>
          ))}
        </div>
        <Button
          icon={isLoading ? "fas fa-spinner fa-spin" : "fas fa-plus"}
          type="submit"
          width={"100%"}
          iconHover="Claro"
          text={isLoading ? "Cargando..." : "CREAR TAREA"}
          className="button_add_task"
          disabled={isLoading}
        />
      </form>
    </div>
  );
};

export default memo(CreateTaskForm);
