import { createContext, ReactElement, useCallback, useMemo, useState } from 'react';
import { NominaService, ReportsService } from 'api/nomina';
import { useAuth } from 'api/auth';
import { IModalsNominasPagadas, IRemoteNomina } from 'interfaces/ITalentoHumano';
import { MODALS, Reportes } from 'consts/TH';
import notify from 'devextreme/ui/notify';
import { IFilterChange } from 'components/interfaces/Interfaces';

export interface IContextNominaPagada {
  onHandlerSearchNominaPagada: () => Promise<void>;
  onHandlerReports: (report: Reportes) => Promise<void>;
  onHandlerPeriodoNomina: (e: IFilterChange) => void;
  loading: boolean;
  nominas: IRemoteNomina[];
  clearData: () => void;
  onShowModals: (modal: MODALS) => void;
  modals: IModalsNominasPagadas;
  onHandlerReopenNomina: () => Promise<void>;
}
export const NominaPagadaContext = createContext({} as IContextNominaPagada);
const { Provider } = NominaPagadaContext;

interface IProps {
  children: ReactElement | ReactElement[];
}

export const NominaPagadaProvider = ({ children }: IProps) => {

  const { User } = useAuth();
  const [nominas, setNominas] = useState<IRemoteNomina[]>([]);
  const [periodo, setPeriodo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [modals, setModals] = useState<IModalsNominasPagadas>({ acumulado: false, comisiones: false });

  const reportesService = useMemo(() => {
    return ReportsService(User);
  }, [User]);

  const nominasService = useMemo(() => {
    return NominaService(User);
  }, [User]);

  const onHandlerReports = async (report: Reportes): Promise<void> => {
    if (nominas.length === 0) {
      notify("Primero debe buscar las nominas a generar reporte");
      return
    }
    /**GENERAMOS EL REPORTE SELECCIONADO */
    let wasCreated: boolean = false;
    if (report === "Asimilados") {
      wasCreated = await reportesService.asimilados(periodo);
    }
    if (report === "Billetiza") {
      wasCreated = await reportesService.billetiza(nominas);
    }
    if (report === "Recibos") {
      wasCreated = await reportesService.recibosEfectivo(nominas);
    }
    if (!wasCreated) notify("Ocurrio un error al generar el reporte", "error", 4000);
  }

  const onHandlerSearchNominaPagada = useCallback(async (): Promise<void> => {
    if (!periodo.trim()) {
      notify("No se ha seleccionado el periodo a pagar", "warning", 4000);
      return;
    }
    setLoading(true);
    await nominasService.getNominasPagadas(periodo, (nominas) => {
      nominas.length === 0 && notify("No se pudieron obtener las nominas", "error", 4000);
      setNominas(nominas);
    });
    setLoading(false);
  }, [periodo, nominasService]);

  const onHandlerPeriodoNomina = (e: IFilterChange) => {
    const periodo: string = e.value;
    setPeriodo(periodo);
  }

  const onHandlerReopenNomina = async (): Promise<void> => {
    if (nominas.length === 0) {
      notify("No se ha seleccionado una nomina a reabrir", "warning", 6000);
      return
    }
    const periodo = nominas[0]?.periodo;
    if (!periodo) {
      notify("Periodo no seleccionado", "warning", 6000);
      return;
    }
    setLoading(true);
    await nominasService.reopenNominaPagada(periodo, (result) => {
      const message = result ? "Nómina reabierta con éxito" : "Error al reabrir la nómina";
      const notificationType = result ? "success" : "error";
      if (result) {
        setNominas([]);
        setPeriodo("");
      }
      notify(message, notificationType, 6000);
    });
    setLoading(false);
  }

  const clearData = useCallback(() => {
    setNominas([]);
    setPeriodo("");
  }, []);

  const onShowModals = (modal: MODALS): void => {
    setModals(prev => {
      return {
        ...prev,
        [modal]: !prev[modal]
      }
    })
  }

  const values: IContextNominaPagada = {
    nominas,
    loading,
    onHandlerReports,
    onHandlerPeriodoNomina,
    onHandlerSearchNominaPagada,
    onHandlerReopenNomina,
    clearData,
    onShowModals,
    modals
  }

  return (
    <Provider value={values}>
      {children}
    </Provider>
  )
}
