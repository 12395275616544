export const nombresTareas = [
    { nombre: "Selecciona el nombre de la tarea", value: "" },
    { nombre: "LLAMADA", value: "LLAMADA" },
    { nombre: "CORREO", value: "CORREO" },
    { nombre: "COBRANZA", value: "COBRANZA" },
    { nombre: "WHATSAPP", value: "MENSAJE" },
    { nombre: "ENVIO ESTADO DE CUENTA", value: "ENVIO ESTADO DE CUENTA" },
    { nombre: "REVISION DE PORTAL", value: "REVISION DE PORTAL" },
    { nombre: "SOLICITUD DE PAGO", value: "SOLICITUD DE PAGO" },
    { nombre: "OTRO", value: "OTRO" },
  ];
  
  export const tiposServicios = [
    { nombre: "Selecciona un tipo de servicio", value: "" },
    { nombre: "Ocean", value: "Ocean" },
    { nombre: "Air", value: "Air" },
    { nombre: "Ground", value: "Ground" },
    { nombre: "Gastos", value: "Gastos" },
  ];
  
  export const tiposEntidades = [
    { nombre: "Selecciona un tipo de entidad", value: "" },
    { nombre: "Cliente", value: "Client" },
    { nombre: "Proveedor", value: "Vendor" },
  ];
  
  export const tiposUrgencias = [
    { nombre: "Baja", value: "Baja" },
    { nombre: "Media", value: "Media" },
    { nombre: "Alta", value: "Alta" },
  ];
  
  export const tiposEstatus = [
    { nombre: "Pendiente", value: "Pendiente" },
    { nombre: "En Proceso", value: "En Proceso" },
    { nombre: "Terminada", value: "Terminada" },
  ];

  export const tiposMonedas = [
    { nombre: "Selecciona una moneda", value: "" },
    { nombre: "MXN", value: "MXN" },
    { nombre: "USD", value: "USD" },
    { nombre: "EUR", value: "EUR" },
  ]
  