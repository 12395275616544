import { lazy, LazyExoticComponent } from "react";
import {
  Home,
  Budget,
  Desglose,
  ConfiguracionBudget,
  ConfiguracionNomina,
  ConfiguracionComisiones,
  ConfiguracionPaises,
  Comisiones,
  Tests,
  Sinc,
  Logs,
  BannerImages,
  ConfiguracionClientesDemoras,
  SeguimientoClientes
} from "pages/Index";
import { DEFAULT_ROUTE } from "consts/User";
import { routesOperaciones } from "./Operaciones/routes";
import { routesAdministracion } from "./Administracion/routes";
import { talentoHumanoRoutes } from "./TH/routes";
import { DEFAULT_PAGE_NAME } from "../consts/User";
import Queue from "pages/config/Queue";
import Testing from "pages/config/Testing";
import ComisionesPendientesVendedor from "pages/comisiones/ComisionesPendientesVendedor";
import Comparativo from "pages/config/Comparativo";

type JSXComponent = () => JSX.Element;

export interface Route {
  id: number;
  to: string;
  path: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent;
  name: string;
  icon?: string;
  items?: Route[];
  title?: string;
  pageName?: string;
}

const configuracionesModules = lazy(
  () =>
    import(
      /* webpackChunkName:"ClientesLayout" */ "routes/ConfiguracionesModules"
    )
);
const itModules = lazy(
  () => import(/* webpackChunkName:"ItLayout" */ "routes/ItModules")
);
const comercialModules = lazy(
  () =>
    import(/* webpackChunkName:"ComercialLayout" */ "routes/ComercialModules")
);
const operacionesModules = lazy(
  () =>
    import(
      /* webpackChunkName:"OperacionesLayout" */ "routes/OperacionesModules"
    )
);
const talentoHumanoModules = lazy(
  () =>
    import(
      /* webpackChunkName:"TalentoHumanoLayout" */ "routes/TalentoHumanoModules"
    )
);
const analiticsModules = lazy(
  () =>
    import(/* webpackChunkName:"AnaliticsLayout" */ "routes/AnaliticsModules")
);
const administracionModules = lazy(
  () =>
    import(
      /* webpackChunkName:"AdministracionLayout" */ "routes/AdministracionModules"
    )
);

export const routesConfiguraciones: Route[] = [
  {
    id: 1,
    path: "/budget",
    to: "/configuraciones/budget",
    Component: ConfiguracionBudget,
    name: "CONFIGURACION",
    icon: "fas fa-chart-area",
    title: "Budget",
    pageName: "Configuración de Budget",
  },
  {
    id: 2,
    path: "/nominas",
    to: "/configuraciones/nominas",
    Component: ConfiguracionNomina,
    name: "CONFIGURACION",
    icon: "fas fa-file-invoice-dollar",
    title: "Nomina",
    pageName: "Configuración de Nomina",
  },
  {
    id: 3,
    path: "/comisiones",
    to: "/configuraciones/comisiones",
    Component: ConfiguracionComisiones,
    name: "CONFIGURACION",
    icon: "fas fa-percent",
    title: "Comisiones",
    pageName: "Configuración de comisiones",
  },
  {
    id: 4,
    path: "/segmentacionPaises",
    to: "/configuraciones/segmentacionPaises",
    Component: ConfiguracionPaises,
    name: "CONFIGURACION",
    icon: "fas fa-globe-americas",
    title: "Estrategias Comerciales",
    pageName: "Configuracion Estrategias Comerciales",
  },
  {
    id: 5,
    path: "/tests",
    to: "/configuraciones/tests",
    Component: Tests,
    name: "CONFIGURACION",
    icon: "fas fa-spell-check",
    title: "Tests",
    pageName: "Tests",
  },
  {
    id: 6,
    path: "/sinc",
    to: "/configuraciones/sinc",
    Component: Sinc,
    name: "CONFIGURACION",
    icon: "fas fa-sync",
    title: "Sincronización",
    pageName: "Sincronización",
  },
  {
    id: 7,
    path: "/queue",
    to: "/configuraciones/queue",
    Component: Queue,
    name: "CONFIGURACION",
    icon: "fas fa-sync",
    title: "Procesos en cola",
    pageName: "Procesos en cola",
  },
  {
    id: 8,
    path: "/logs",
    to: "/configuraciones/logs",
    Component: Logs,
    name: "CONFIGURACION",
    icon: "fas fa-file-alt",
    title: "Logs",
    pageName: "Logs",
  },
  {
    id: 9,
    path: "/BannerImages",
    to: "/configuraciones/BannerImages",
    Component: BannerImages,
    name: "CONFIGURACION",
    icon: "fas fa-images",
    title: "Imagenes del Banner",
    pageName: "Imagenes del Banner",
  },
  {
    id: 10,
    path: "/prueba-testing",
    to: "/configuraciones/prueba-testing",
    Component: Testing,
    name: "CONFIGURACION",
    icon: "fas fa-spell-check",
    title: "Prueba Testing",
    pageName: "Prueba Testing",
  },
  {
    id: 11,
    path: "/comparativo",
    to: "/configuraciones/comparativo",
    Component: Comparativo,
    name: "CONFIGURACION",
    icon: "fas fa-equals",
    title: "Comparativo",
    pageName: "Comparativo",
  },
  {
    id: 11,
    path: "/clientes-demoras",
    to: "/configuraciones/clientes-demoras",
    Component: ConfiguracionClientesDemoras,
    name: "CONFIGURACION",
    icon: "fas fa-clock",
    title: "Clientes Demoras",
    pageName: "Clientes Demoras",
  },
];

export const routesIT: Route[] = [
  /*{
    id: 1,
    path: "/permisos",
    to: "/it/permisos",
    Component: Permisos,
    name: "TI",
    icon: "fas fa-user-lock",
    title: "Permisos"
  },*/
  // {
  //   id: 2,
  //   path: "/perfil",
  //   to: "/it/perfil",
  //   Component: Perfil,
  //   name: "IT",
  //   icon: "fas fa-id-card",
  //   title: "Perfil",
  //   pageName :"Perfil de Colaborador"
  // }
];

export const routesAnalitics: Route[] = [
  {
    id: 1,
    path: "/budget",
    to: "/analitics/budget",
    Component: Budget,
    name: DEFAULT_ROUTE,
    icon: "fas fa-chart-area",
    title: "Budget",
    pageName: "Budget",
  },
  {
    id: 2,
    path: "/desglose",
    to: "/analitics/desglose",
    Component: Desglose,
    icon: "fas fa-globe",
    name: DEFAULT_ROUTE,
    title: "Desglose",
    pageName: "Desglose de Operaciones",
  },
];

export const routesComercial: Route[] = [
  {
    id: 1,
    to: "/comercial/comisiones",
    path: "/comisiones",
    Component: Comisiones,
    name: "COMERCIAL",
    icon: "fas fa-percent",
    title: "Comisiones pagadas",
    pageName: "Comisiones Pagadas",
  },
  {
    id: 2,
    to: "/comercial/comisiones-pendientes",
    path: "/comisiones-pendientes",
    Component: ComisionesPendientesVendedor,
    name: "COMERCIAL",
    icon: "fas fa-percent",
    title: "Comisiones Pendientes",
    pageName: "Comisiones Pendientes",
  },
  {
    id: 3,
    to: "/comercial/seguimiento-cobranza",
    path: "/seguimiento-cobranza",
    Component: SeguimientoClientes,
    icon: "fas fa-handshake",
    name: "COMERCIAL",
    title: "Seguimiento Cobranza",
    pageName: "Seguimiento Cobranza",
  },
];

export const routes: Route[] = [
  {
    id: 1,
    to: "/home",
    path: "home",
    Component: Home,
    name: DEFAULT_ROUTE,
    icon: "fas fa-home",
    pageName: DEFAULT_PAGE_NAME,
  },
  {
    id: 2,
    to: "/analitics/",
    path: "/analitics/*",
    Component: analiticsModules,
    name: DEFAULT_ROUTE,
    icon: "fas fa-chart-pie",
    title: "Analitics",
    items: routesAnalitics,
  },
  {
    id: 3,
    to: "/operaciones/",
    path: "/operaciones/*",
    Component: operacionesModules,
    name: "OPERACIONES",
    icon: "fas fa-globe-americas",
    title: "Operaciones",
    items: routesOperaciones,
  },
  {
    id: 4,
    to: "/administracion/",
    path: "/administracion/*",
    Component: administracionModules,
    name: DEFAULT_ROUTE,
    icon: "fas fa-wallet",
    title: "Administración",
    items: routesAdministracion,
  },
  {
    id: 5,
    to: "/th/",
    path: "/th/*",
    Component: talentoHumanoModules,
    name: "TH",
    title: "Talento Humano",
    icon: "fas fa-user-alt",
    items: talentoHumanoRoutes,
  },
  {
    id: 6,
    to: "/it/",
    path: "/it/*",
    Component: itModules,
    name: "IT",
    title: "IT",
    icon: "fas fa-code",
    items: routesIT,
  },
  {
    id: 7,
    to: "/configuraciones/",
    path: "/configuraciones/*",
    Component: configuracionesModules,
    name: "CONFIGURACION",
    title: "Configuración",
    icon: "fas fa-tools",
    items: routesConfiguraciones,
  },
  {
    id: 8,
    to: "/comercial/",
    path: "/comercial/*",
    Component: comercialModules,
    name: "COMERCIAL",
    icon: "fab fa-shopify",
    items: routesComercial,
    title: "Comercial",
  },
];
