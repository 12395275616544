import { memo, useCallback, useMemo, useState } from "react";
import { useTask } from "contexts/TaskContext";
import GridSubtareas from "./GridSubtareas"
import { Popup } from "devextreme-react/popup";
import Button from "components/Button";
import CreateSubTaskForm from "./CreateSubtaskForm";
import { ScrollView } from "devextreme-react";

const ModalSubTarea = ({
  modalSubTarea,
  closePopup
}) => {

  const [modalAddSubTarea, setModalAddSubTarea] = useState(false);

  const {
    dataSubTareas,
    deleteTarea,
    handleEditTarea,
    handleArchivosTarea,
    handleSubTareas
  } = useTask();

  const Tarea_Padre_Id = dataSubTareas.Tarea_Padre_Id;
  const Entidad_Guid = dataSubTareas.Entidad_Guid;
  const Tarea_Padre = dataSubTareas.Tarea_Padre;

  const refreshSubTareas = useCallback(async () => {
    await handleSubTareas(Tarea_Padre_Id, Entidad_Guid);
  }, [handleSubTareas, Tarea_Padre_Id, Entidad_Guid]);

  const renderTitle = () => {
      return (
        <div className="modal-header">
          <h3 className="title_task">Subtareas</h3>
          <button onClick={closePopup} className="close-button">
            <i className="fas fa-times"></i>
          </button>
        </div>
      );
  };

  const memoizedDataSubtareas = useMemo(() => dataSubTareas.data, [dataSubTareas.data]);

  const memoizedDeleteTarea = useCallback(async (id) => {
    deleteTarea(id);
    await refreshSubTareas();
  }, [deleteTarea, refreshSubTareas]);

  const memoizedHandleEditTarea = useCallback(async (tarea) => {
    handleEditTarea(tarea);
    await refreshSubTareas();
  }, [handleEditTarea, refreshSubTareas]);

  const handleAddSubTarea = () => {
    setModalAddSubTarea(true);
  }

  return (
    <Popup
      width={1100}
      height={650}
      dragEnabled={true}
      visible={modalSubTarea}
      titleRender={renderTitle}
      closeOnOutsideClick={true}
      onHiding={closePopup}
    >
      <GridSubtareas
        subTareas={memoizedDataSubtareas}
        handleEditSubtarea={memoizedHandleEditTarea}
        deleteSubtarea={memoizedDeleteTarea}
        handleArchivosTarea={handleArchivosTarea}
      />
      <div style={{display: "flex", justifyContent: "flex-end"}}>
        <Button
          width={120}
          type="button"
          iconHover="Claro"
          icon={"fas fa-plus"}
          text="Agregar subtarea"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleAddSubTarea()}
        />
      </div>
      {modalAddSubTarea && (
        <Popup
          visible={modalAddSubTarea}
          width={750}
          height={600}
          onHiding={() => setModalAddSubTarea(false)}
        >
        <ScrollView>
          <CreateSubTaskForm
            isPaymentType={true}
            Tarea_Padre_Id={Tarea_Padre_Id}
            Tarea_Padre={Tarea_Padre}
            Entidad_Guid={Entidad_Guid}
            onSuccess={refreshSubTareas}
          />
        </ScrollView>
        </Popup>
      )}
    </Popup>
);
};

export default memo(ModalSubTarea);
