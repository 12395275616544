import { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "components/Index";
import { useNavigate } from "react-router-dom";
import { ColumsTareas, ColumsTareasSolicitudPago } from "functions/Tareas";
import { useTask } from "../../../contexts/TaskContext";
import DataGrid, { Column, Pager } from "devextreme-react/data-grid";

const pageSizes = [25, 50, 75, 100];

const GridTareas = ({ dataTareas, handleEditTarea, deleteTarea, handleArchivosTarea, handleSubTareas ,isPaymentType }) => {
  const navigate = useNavigate();
  const { setEntidadGuid, setRazonSocial } = useTask();

  const handleClienteClick = (entidad) => {
    setEntidadGuid(entidad.Entidad_Guid);
    setRazonSocial(entidad.Entidad_Razon_Social);
    navigate("/administracion/clientes/perfilCliente");
  };

  const handleOnClickEdit = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-edit"}
        onClick={() => handleEditTarea(data.data)}
      />
    );
  };

  const handleOnClickDelete = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-trash"}
        onClick={() => deleteTarea(data.data.Tarea_Id)}
      />
    );
  };

  const handleOnClickPerfil = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-user-tie"}
        onClick={() => handleClienteClick(data.data.Entidad_Razon_Social)}
      />
    );
  };

  const handleOnClickArchivos = ({ data }) => {
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-file"}
        onClick={() => handleArchivosTarea(data.data.Tarea_Id)}
      />
    );
  }

  const handleOnClickSubTareas = ({data}) => {
    const {Tarea_Id, Entidad_Guid} = data.data;
    return (
      <Button
        width={75}
        type="button"
        iconHover="Claro"
        icon={"fas fa-list-alt"}
        onClick={() => handleSubTareas(Tarea_Id, Entidad_Guid, data.data)}
      />
    );
  }

  return (
    isPaymentType ? (
      <DataGrid
        dataSource={dataTareas}
        noDataText="No hay información disponible"
        showBorders={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        columnResizingMode="widget"
        columnMinWidth={40}
        scrolling={{
          columnRenderingMode: "virtual",
        }}
        paging={{ enabled: true }}
        headerFilter={{ visible: true, allowSearch: true }}
        searchPanel={{
          visible: true,
          highlightCaseSensitive: false,
          placeholder: "Buscar",
          width: 250,
        }}
        groupPanel={{
          visible: true,
          emptyPanelText: "Arrastra aqui 👇 la columna a agrupar",
        }}
        grouping={{
          texts: { groupContinuesMessage: "Continua en la siguiente página" },
          autoExpandAll: true,
        }}
        showRowLines={true}
      >
        {ColumsTareasSolicitudPago.map((column, index) => (
          <Column {...column} key={index} />
        ))}
        <Column caption="Archivos" alignment="center" cellComponent={handleOnClickArchivos} width={90} />
        <Column caption="Subtareas" alignment="center" cellComponent={handleOnClickSubTareas} width={90} />
        <Column caption="Editar" alignment="center" cellComponent={handleOnClickEdit} width={90} />
        <Column caption="Eliminar" alignment="center" cellComponent={handleOnClickDelete} width={90}/>
        <Column caption="Perfil" alignment="center" cellComponent={handleOnClickPerfil} width={90} />
        <Pager
          visible={true}
          allowedPageSizes={pageSizes}
          showPageSizeSelector={true}
        />
      </DataGrid>
    ) : (
      <DataGrid
      dataSource={dataTareas}
      noDataText="No hay información disponible"
      showBorders={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      columnResizingMode="widget"
      columnMinWidth={40}
      scrolling={{
        columnRenderingMode: "virtual",
      }}
      paging={{ enabled: true }}
      headerFilter={{ visible: true, allowSearch: true }}
      searchPanel={{
        visible: true,
        highlightCaseSensitive: false,
        placeholder: "Buscar",
        width: 250,
      }}
      groupPanel={{
        visible: true,
        emptyPanelText: "Arrastra aqui 👇 la columna a agrupar",
      }}
      grouping={{
        texts: { groupContinuesMessage: "Continua en la siguiente página" },
        autoExpandAll: true,
      }}
      showRowLines={true}
    >
      {ColumsTareas.map((column, index) => (
        <Column {...column} key={index} />
      ))}
      <Column caption="Archivos" alignment="center" cellComponent={handleOnClickArchivos} width={90} />
      <Column caption="Editar" alignment="center" cellComponent={handleOnClickEdit} width={90} />
      <Column caption="Eliminar" alignment="center" cellComponent={handleOnClickDelete} width={90} />
      <Column caption="Perfil" alignment="center" cellComponent={handleOnClickPerfil} width={90} />
      <Pager
        visible={true}
        allowedPageSizes={pageSizes}
        showPageSizeSelector={true}
      />
    </DataGrid>
  )
);
};

GridTareas.propTypes = {
  dataTareas: PropTypes.array,
  keyStateStoraged: PropTypes.string,
  onStartEditing: PropTypes.func,
  isSynchronizing: PropTypes.bool,
};

export default memo(GridTareas);
