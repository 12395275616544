/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './styles/estilos.scss';
import { PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import MenuFilters from './components/MenuFilters';
import CreateTaskFormPopup from './components/CreateTaskFormPopup'
import { TaskProvider } from 'contexts/TaskContext';

const config = {
  auth: {
    clientId: "c4258e1b-cb20-4a55-89a9-761d49eb84d5",
    authority: "https://login.microsoftonline.com/bbbbf4d0-5df6-47f1-9d92-18b51bb013ff"
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
}

export const instance = new PublicClientApplication(config);

const Demoras = () => {

  const [bearerToken, setBearerToken] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const getPowerBiToken = async () => {
    let token;
    const uri = window.location.href;
    
    const loginRequest: PopupRequest = {
      scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"], 
      redirectUri: uri, 
    };
  
    try {
        await instance.initialize();
        const res = await instance.acquireTokenSilent(loginRequest);
        token = res?.accessToken
    } catch {
        const res = await instance.acquireTokenPopup(loginRequest);
        token = res?.accessToken;
    }
    setBearerToken(token);
  };

  const getToken = async () => {
    const res = await axios.post('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
      datasets: [
        {
          id: "c0640c2a-d93f-4e72-95e1-6e4feaabecf3"
        }
      ],
      reports: [
        {
          id: "75899dda-b08c-45a9-b08b-6b0038c5cefd"
        }
      ]
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearerToken}`
      }
    });

    setToken(res.data.token);
  }

  useEffect(() => {
    getPowerBiToken();
  }, []);

  useEffect(() => {
    if (bearerToken) {
      getToken();
    }
  }, [bearerToken]);

  return (
    <div className='dashboardContainer'>
      <TaskProvider>
        <MenuFilters>
          <CreateTaskFormPopup/>
        </MenuFilters>
      </TaskProvider>
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: '75899dda-b08c-45a9-b08b-6b0038c5cefd',
          embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=75899dda-b08c-45a9-b08b-6b0038c5cefd&groupId=34b3ffff-448b-498f-a2e1-7f64f259b80d&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVNPVVRILUNFTlRSQUwtVVMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
          accessToken: token || '',
          tokenType: models.TokenType.Embed, 
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent,
          }
        }}

        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event); }],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }

        cssClassName={"reportClass"}
      />
    </div>
  )
}

export default Demoras