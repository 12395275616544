import axios, { AxiosRequestConfig } from 'axios';
import { getDataAsync } from 'api/fetch';
import { URL_API_OPERACIONES } from 'consts/Services_URL';
import { IResponse } from './interfaces/interfaces';
import { IUser } from 'interfaces/IUsuario';
import { IFiltersDesgloce, IOpsByTitle, IRemoteMotivo } from 'interfaces/IOperaciones';
import { HttpErrorMessage } from 'utils/ResponseAPI';
import { IRemoteEstrategias, IRemoteAños, IRemoteVendedores, IRemoteModoTransporte, IRemoteCustomerService, IRemoteEjecutivoOperaciones, IRemoteTransportista } from 'interfaces/IOperaciones';
import { IFilterMultiple, IFilterData } from 'components/interfaces/Interfaces';
import { createNewFilter, createNewFilterMultiple } from 'utils/Functions';
import { IFilterOpsTerrestres, ITitulosBudget, IRemoteBudget } from '../interfaces/IOperaciones';
import { Meses } from 'utils/Global';
import { AlcanceOpcion, DiferenciaOpcion, TITLE_ALCANCE_BUDGET, TITLE_DIFF_BUDGET } from 'consts/Operaciones';
import { formatoDecimales } from 'utils/Formatos';
import { PivotGridDataSourceField } from 'devextreme/ui/pivot_grid/data_source';

export interface IDataOpsCanceladas {
  años: number[];
  estrategias: string[];
  motivosCancelacion: string[];
}
export interface ICatalogosOpsCanceladas {
  años: IFilterMultiple;
  estrategias: IFilterData;
  motivosCancelacion: IFilterData;
}

interface IResponseCatalogosOpsCanceladas extends IResponse {
  data?: ICatalogosOpsCanceladas
}
type opciones = "Alcance" | "Diferencia";

export const OperacionesService = (User?: IUser) => {

  const token: string = User ? User.token! : "";
  const division: string = User ? User.divisionSeleccionada! : "";
  const headers: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };

  return {
    getDataDesglose: async (filters: string): Promise<IResponse> => {
      return await getDataAsync(`${URL_API_OPERACIONES}desgloce?filtros=${filters}&division=${division}`, headers);
    },
    getCatalogosDesglose: async (): Promise<IResponse> => {
      try {
        return await Promise.all([
          axios.get(`${URL_API_OPERACIONES}catalogos/vendedores?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/customerService?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/modoTransporte?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/ejecutivoOperaciones?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/aniosOperaciones?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/carriers?division=${division}`, headers)
        ]).then(([vendedores, customersService, modosTransporte, ejecutivosOperaciones, añosOperaciones, transportistas]) => {

          const catalogos: IFiltersDesgloce = {
            años: añosOperaciones.data.map((año: IRemoteAños) => { return año.anio }),
            vendedores: vendedores.data.map((vendedor: IRemoteVendedores) => { return vendedor.vendedor }),
            modosTransporte: modosTransporte.data.map((modo: IRemoteModoTransporte) => { return modo.embarque_Modo_Transportacion }),
            customer: customersService.data.map((customer: IRemoteCustomerService) => customer.embarque_Personalizado_Customer_Service),
            operativo: ejecutivosOperaciones.data.map((ejecutivoOperacion: IRemoteEjecutivoOperaciones) => ejecutivoOperacion.ejecutivo_Operaciones),
            transportista: transportistas.data.map((carrier: IRemoteTransportista) => { return carrier.carrier })
          }
          const itemsFilter: IFilterMultiple[] = setFiltersMultiple(catalogos);
          const reponse: IResponse = { data: itemsFilter, result: true }
          return reponse;
        }).catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
          const response: IResponse = { error: messageError, estatus: error.status, result: false }
          return response;
        })
      } catch (err: any) {
        return { error: err, result: false }
      }

    },
    getDataOpsCanceladas: async (filters: string): Promise<IResponse> => {
      return await getDataAsync(`${URL_API_OPERACIONES}operacionesCanceladas?filtros=${filters}&division=${division}`, headers);
    },
    getCatalogosOpsCanceladas: async (): Promise<IResponseCatalogosOpsCanceladas> => {
      try {
        return await Promise.all([
          axios.get(`${URL_API_OPERACIONES}catalogos/aniosOperaciones?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/estrategiasComerciales?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/motivoCancelaciones?division=${division}`, headers)
        ]).then(([años, estrategias, motivos]) => {

          const catalogos: IDataOpsCanceladas = {
            años: años.data.map((año: IRemoteAños) => { return año.anio }),
            estrategias: estrategias.data.map((estrategia: IRemoteEstrategias) => { return estrategia.estrategia_Origen }),
            motivosCancelacion: motivos.data.map((motivo: IRemoteMotivo) => { return motivo.motivo_Cancelacion })
          }
          const añosFilter: IFilterMultiple = createNewFilterMultiple(catalogos.años, "años", []);
          const estrategiasFilter: IFilterData = createNewFilter(catalogos.estrategias, "", "estrategiaOrigen");
          const motivosCancelacionesFilter: IFilterData = createNewFilter(catalogos.motivosCancelacion, "", "motivoCancelacion");

          const response: IResponseCatalogosOpsCanceladas = {
            data: {
              años: añosFilter,
              estrategias: estrategiasFilter,
              motivosCancelacion: motivosCancelacionesFilter
            },
            result: true
          }
          return response;
        }).catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
          const response: IResponseCatalogosOpsCanceladas = { error: messageError, estatus: error.status, result: false }
          return response
        })
      } catch (err: any) {
        return { error: err, result: false, estatus: 500 }
      }
    },
    getDataOpsTerrestres: async (filters: string): Promise<IResponse> => {
      const encodedFilters = encodeURIComponent(filters);
      return await getDataAsync(`${URL_API_OPERACIONES}indicadoresTerrestres?filtros=${encodedFilters}&division=${division}`, headers);
    },
    getCatalogosOpsTerrestres: async (): Promise<IResponse> => {
      try {
        return await Promise.all([
          axios.get(`${URL_API_OPERACIONES}catalogos/modoTransporte?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/ejecutivoOperaciones?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/aniosOperaciones?division=${division}`, headers),
          axios.get(`${URL_API_OPERACIONES}catalogos/carriers?division=${division}`, headers)
        ]).then(([modosTransporte, operativos, años, transportistas]) => {

          const catalogos: IFilterOpsTerrestres = {
            años: años.data.map((año: IRemoteAños) => { return año.anio }),
            operativo: operativos.data.map((operativo: IRemoteEjecutivoOperaciones) => { return operativo.ejecutivo_Operaciones }),
            modosTransporte: modosTransporte.data.map((modo: IRemoteModoTransporte) => { return modo.embarque_Modo_Transportacion }),
            transportista: transportistas.data.map((transportista: IRemoteTransportista) => { return transportista.carrier })
          }
          const filters: IFilterMultiple[] = setFiltersMultiple(catalogos);
          const response: IResponse = { data: filters, result: true };
          return response
        }).catch((err) => {
          const error = JSON.parse(JSON.stringify(err));
          const messageError = error.status ? HttpErrorMessage[error.status] : error.message;
          const response: IResponse = { error: messageError, estatus: error.status, result: false };
          return response;
        });
      } catch (err: any) {
        return { error: err, estatus: 500, result: false };
      }
    },
    getOpsMensualesBudget: (titulos: ITitulosBudget, budget: IRemoteBudget[]): IOpsByTitle[] => {
      let totalBudget: number = 0;
      let totalOpsActual: number = 0;
      const opsByTitle: IOpsByTitle[] = Object.values(titulos)?.map((title: string) => {
        let values: string[] = [title];
        let value: number = 0;
        for (let i: number = 1; i < Meses.length + 1; i++) {
          const opsMensual: IRemoteBudget[] = budget.filter((ops) => ops.mes === i);
          let totalOperaciones: number = 0;
          if (title === TITLE_ALCANCE_BUDGET) {
            totalOperaciones = getAlcance_Diff(opsMensual, "Alcance", titulos);
          }
          if (title === TITLE_DIFF_BUDGET) {
            totalOperaciones = getAlcance_Diff(opsMensual, "Diferencia", titulos);
          }
          if (title !== TITLE_ALCANCE_BUDGET && title !== TITLE_DIFF_BUDGET) {
            const total = opsMensual.find((ops) => ops.titulo === title);
            totalOperaciones = !total ? 0 : total.total_Operaciones;
          }
          value += totalOperaciones;
          values.push(
            title === TITLE_ALCANCE_BUDGET ? `${totalOperaciones}%` : `${totalOperaciones}`
          );
        }
        totalBudget += titulos.budgetActual === title ? value : 0;
        totalOpsActual += titulos.opsActual === title ? value : 0;
        const totalOps: string = getTotalOps({ title, totalBudget, totalOpsActual, valueOps: value });
        values.push(totalOps);
        return { [title]: values }
      })
      return opsByTitle;
    }
  }
}

/**ASIGNAR TODOS LOS FILTROS MULTIPLE POR KEY DEL FILTRO */
const setFiltersMultiple = (catalogos: any): IFilterMultiple[] => {
  const keysFilters: string[] = Object.keys(catalogos);
  const itemsFilter: IFilterMultiple[] = keysFilters.map((keyFilter: string) => {
    return createNewFilterMultiple(catalogos[keyFilter], keyFilter, []);
  });
  return itemsFilter;
}

/**OBTENER ALCANCE O DIFERENCIA BUDGET */
const getAlcance_Diff = (opsMensual: IRemoteBudget[], opcion: opciones, titulos: ITitulosBudget): number => {

  const [budget, opsActual] = opsMensual.filter((ops) => ops.titulo === titulos.budgetActual || ops.titulo === titulos.opsActual);
  if (!budget || !opsActual) return 0;
  const budgetTotalOps: number = budget.total_Operaciones;
  const opsTotal: number = opsActual.total_Operaciones;

  if (opcion === AlcanceOpcion) {
    return formatoDecimales((opsTotal / budgetTotalOps) * 100, 2);
  }
  if (opcion === DiferenciaOpcion) {
    return formatoDecimales(opsTotal - budgetTotalOps, 2);
  }
  return 0;
}
interface IGetTotal {
  title: string;
  totalOpsActual: number;
  totalBudget: number;
  valueOps: number;

}
/** CALCULAR EL TOTAL DE LA COLUMNA TOTALES */
const getTotalOps = ({ title, totalBudget, totalOpsActual, valueOps }: IGetTotal): string => {
  if (title === TITLE_ALCANCE_BUDGET) {
    return `${formatoDecimales((totalOpsActual / totalBudget) * 100, 2)} %`;
  }
  if (title === TITLE_DIFF_BUDGET) {
    return `${formatoDecimales(totalBudget - totalOpsActual, 2)}`;
  }
  return `${valueOps}`;
}

/**FIELDS PIVOT OPERACIONES TERRESTRES */
const calcularOperacionesEnTiempo = (options: any) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.total = 0;
  }
  if (options.summaryProcess === 'calculate') {
    if (options.value === "EN TIEMPO") {
      options.total++;
    }
  }
  if (options.summaryProcess === 'finalize') {
    options.totalValue = options.total;
  }
}
const calcularPorcentajeOperacionesEnTiempo = (options: any, key: string) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.totalElementos = 0;
    options.totalEnTiempo = 0;
  }
  if (options.summaryProcess === 'calculate') {
    const operacion = options.value[key];
    if (operacion === "EN TIEMPO") {
      options.totalEnTiempo++;
    }
    options.totalElementos++;
  }
  if (options.summaryProcess === 'finalize') {
    if (options.totalElementos === 0 || options.totalEnTiempo === 0) {
      options.totalValue = 0;
    }
    if (options.totalElementos > 0 || options.totalEnTiempo > 0) {
      options.totalValue = Math.round((options.totalEnTiempo * 100) / options.totalElementos);
    }
  }
}
const calcularPorcentajePODMX = (options: any) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.totalElementos = 0;
    options.totalEnTiempo = 0;
  }
  if (options.summaryProcess === 'calculate') {
    const { transportista_Mexicano, poD_LT_MX } = options.value;
    if (poD_LT_MX === "EN TIEMPO" && transportista_Mexicano !== "") {
      options.totalEnTiempo++;
    }
    if (transportista_Mexicano !== "") {
      options.totalElementos++;
    }
  }
  if (options.summaryProcess === 'finalize') {
    if (options.totalElementos === 0 || options.totalEnTiempo === 0) {
      options.totalValue = 0;
    }
    if (options.totalElementos > 0 || options.totalEnTiempo > 0) {
      options.totalValue = Math.round((options.totalEnTiempo * 100) / options.totalElementos);
    }
  }
}
const calcularPorcentajePODAM = (options: any) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.totalElementos = 0;
    options.totalEnTiempo = 0;
  }
  if (options.summaryProcess === 'calculate') {
    const { transportista_Americano, poD_LT_AM } = options.value;
    if (poD_LT_AM === "EN TIEMPO" && transportista_Americano !== "") {
      options.totalEnTiempo++;
    }
    if (transportista_Americano !== "") {
      options.totalElementos++;
    }
  }
  if (options.summaryProcess === 'finalize') {
    if (options.totalElementos === 0 || options.totalEnTiempo === 0) {
      options.totalValue = 0;
    }
    if (options.totalElementos > 0 || options.totalEnTiempo > 0) {
      options.totalValue = Math.round((options.totalEnTiempo * 100) / options.totalElementos);
    }
  }

}
const calcularOperacionesEnTiempoPODMX = (options: any) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.total = 0;
  }
  if (options.summaryProcess === 'calculate') {
    const { transportista_Mexicano, poD_LT_MX } = options.value;
    if (poD_LT_MX === "EN TIEMPO" && transportista_Mexicano !== "") {
      options.total++;
    }
  }
  if (options.summaryProcess === 'finalize') {
    options.totalValue = options.total;
  }
}
const calcularOperacionesEnTiempoPODAM = (options: any) => {
  if (options.summaryProcess === 'start') {
    options.totalValue = 0;
    options.total = 0;
  }
  if (options.summaryProcess === 'calculate') {
    const { transportista_Americano, poD_LT_AM } = options.value;
    if (poD_LT_AM === "EN TIEMPO" && transportista_Americano !== "") {
      options.total++;
    }
  }
  if (options.summaryProcess === 'finalize') {
    options.totalValue = options.total;
  }
}
export const _fieldsPivotOpsTerrestres = [
  {
    caption: 'Transportista',
    dataField: 'carrier',
    area: 'row',
    expand: false
  },
  {
    dataField: 'real_Pick_Up',
    dataType: 'date',
    area: 'column',
    expanded: true,
    width: 74
  },
  {
    groupName: 'real_Pick_Up',
    groupInterval: 'quarter',
    visible: false
  },
  {
    groupName: 'real_Pick_Up',
    groupInterval: 'month',
    visible: true
  },
  {
    caption: "Recoleccion (Pz)",
    dataField: "recoleccion",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularOperacionesEnTiempo,
    area: "data"
  },
  {
    caption: "Recoleccion (%)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: (options: any) => calcularPorcentajeOperacionesEnTiempo(options, "recoleccion")
  },
  {
    caption: "Entrega (Pz)",
    dataField: "entrega",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularOperacionesEnTiempo,
    area: "data"
  },
  {
    caption: "Entrega (%)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: (options: any) => calcularPorcentajeOperacionesEnTiempo(options, "entrega")
  },
  {
    caption: "Sol Fact (Pz)",
    dataField: "sol_Fact",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularOperacionesEnTiempo,
    area: "data"
  },
  {
    caption: "Sol Fact (%)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: (options: any) => calcularPorcentajeOperacionesEnTiempo(options, "sol_Fact")
  },
  {
    caption: "POD LT AM (Pz)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularOperacionesEnTiempoPODAM,
    area: "data"
  },
  {
    caption: "POD LT AM (%)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularPorcentajePODAM
  },
  {
    caption: "POD LT MX (Pz)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularOperacionesEnTiempoPODMX,
    area: "data"
  },
  {
    caption: "POD LT MX (%)",
    summaryType: "custom",
    width: 80,
    calculateCustomSummary: calcularPorcentajePODMX
  },
  {
    caption: "Total (Pz)",
    dataField: "embarque_Total_Piezas",
    summaryType: "sum",
    width: 80,
    area: "data"
  }
]
/**---------------------------------------------------------- */

/**FIELDS DESGLOSE OPS */
export const _FieldDesgloce: PivotGridDataSourceField[] = [
  {
    caption: 'Cliente',
    width: 450,
    dataField: 'cliente',
    area: 'row',
    sortBySummaryField: 'Total',
  },
  {
    caption: 'Modo de Transportación',
    dataField: 'embarque_Modo_Transportacion',
    width: 150,
    area: 'row',
  },
  {
    caption: 'Ciudad',
    dataField: 'puerto_Ciudad_Origen',
    width: 150,
    area: 'row',
  },
  {
    dataField: 'fecha_Pick_Up',
    dataType: 'date',
    area: 'column',
    expanded: true,
    width: 74,
  },
  {
    groupName: 'fecha_Pick_Up',
    groupInterval: 'quarter',
    visible: false,
  },
  {
    groupName: 'fecha_Pick_Up',
    groupInterval: 'month',
    visible: true,
  },
  {
    caption: 'Piezas',
    dataField: 'embarque_Total_Piezas',
    dataType: 'number',
    summaryType: 'sum',
    width: 80,
    area: 'data',
  },
  {
    caption: 'TEU',
    dataField: 'teu',
    dataType: 'number',
    summaryType: 'sum',
    width: 80,
    area: 'data',
  }
]