import {
  useState,
  ReactNode,
  useContext,
  createContext,
  useMemo,
} from "react";
import {
  ITarea,
  IUpdateTarea,
  IGetDataTareas,
  IGetDataSubtareas,
  IGetDataEntidades,
  IGetDataResponsables,
  IEntidades,
} from "interfaces/ITareas";
import { useTareas } from "pages/tareas/hooks/useTareas";
import { ITabs } from "functions/Tareas";

interface TaskContextType {
  tarea: ITarea | null;
  dataTareas: IGetDataTareas;
  tabVisible: ITabs;
  modalStatus: boolean;
  updateTarea: (tarea: IUpdateTarea) => void;
  deleteTarea: (id: number) => void;
  responsables: IGetDataResponsables;
  entidadDefined: IGetDataEntidades;
  setModalStatus: (status: boolean) => void;
  handleEditTarea: (tarea: ITarea) => void;
  handlerOnSelectTab: (tab: ITabs) => void;
  entidadGuid: string | null;
  razonSocial: string | null;
  setEntidadGuid: (guid: string | null) => void;
  setRazonSocial: (razonSocial: string | null) => void;
  addTarea: (tarea: any) => Promise<void>;
  getEntidades: (tipoEntidad: string) => Promise<IEntidades[]>;
  onHandlerSync: (data: any[]) => Promise<void>;
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  loading: boolean;
  uploadFiles: (files: File[]) => Promise<{ success: boolean, url: string, name: string }[]>;
  modalArchivos: boolean;
  setModalArchivos: (status: boolean) => void;
  dataArchivos: any[];
  handleArchivosTarea: (id: number) => void;
  addArchivosTarea: (data: any) => void;
  deleteArchivoTarea: (data: any) => void;
  setDataArchivos: React.Dispatch<React.SetStateAction<any[]>>;
  modalTaskShortcut: boolean;
  setModalTaskShortcut: (status: boolean) => void;
  modalSubTareas: boolean;
  setModalSubTareas: (status: boolean) => void;
  handleSubTareas: (id: number, entidad_guid: string, tarea_padre: ITarea) => void;
  dataSubTareas: IGetDataSubtareas;
  setDataSubTareas: React.Dispatch<React.SetStateAction<IGetDataSubtareas>>;
}

export const TaskContext = createContext<TaskContextType | undefined>(
  undefined
);

export const TaskProvider = ({ children }: { children: ReactNode }) => {
  const {
    tarea,
    addTarea,
    dataTareas,
    tabVisible,
    modalStatus,
    updateTarea,
    deleteTarea,
    responsables,
    getEntidades,
    entidadDefined,
    setModalStatus,
    handleEditTarea,
    handlerOnSelectTab,
    onHandlerSync,
    data,
    setData,
    loading,
    uploadFiles,
    modalArchivos,
    dataArchivos,
    setModalArchivos,
    handleArchivosTarea,
    addArchivosTarea,
    deleteArchivoTarea,
    setDataArchivos,
    modalSubTareas,
    setModalSubTareas,
    handleSubTareas,
    dataSubTareas,
    setDataSubTareas
  } = useTareas();

  const [entidadGuid, setEntidadGuid] = useState<string | null>(null);
  const [razonSocial, setRazonSocial] = useState<string | null>(null);
  const [modalTaskShortcut, setModalTaskShortcut] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      tarea,
      dataTareas,
      tabVisible,
      modalStatus,
      updateTarea,
      deleteTarea,
      responsables,
      entidadDefined,
      setModalStatus,
      handleEditTarea,
      handlerOnSelectTab,
      entidadGuid,
      setEntidadGuid,
      razonSocial,
      setRazonSocial,
      addTarea,
      getEntidades,
      onHandlerSync,
      data,
      setData,
      loading,
      uploadFiles,
      modalArchivos,
      setModalArchivos,
      dataArchivos,
      handleArchivosTarea,
      addArchivosTarea,
      deleteArchivoTarea,
      setDataArchivos,
      modalTaskShortcut,
      setModalTaskShortcut,
      modalSubTareas,
      setModalSubTareas,
      handleSubTareas,
      dataSubTareas,
      setDataSubTareas
    }),
    [
      tarea,
      dataTareas,
      tabVisible,
      modalStatus,
      updateTarea,
      deleteTarea,
      responsables,
      entidadDefined,
      setModalStatus,
      handleEditTarea,
      handlerOnSelectTab,
      entidadGuid,
      razonSocial,
      addTarea,
      getEntidades,
      onHandlerSync,
      data,
      setData,
      loading,
      uploadFiles,
      modalArchivos,
      setModalArchivos,
      dataArchivos,
      handleArchivosTarea,
      addArchivosTarea,
      deleteArchivoTarea,
      setDataArchivos,
      modalTaskShortcut,
      setModalTaskShortcut,
      modalSubTareas,
      setModalSubTareas,
      handleSubTareas,
      dataSubTareas,
      setDataSubTareas
    ]
  );

  return (
    <TaskContext.Provider value={contextValue}>
      {children}
    </TaskContext.Provider>
  );
};

export const useTask = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error("useTask must be used within a TaskProvider");
  }
  return context;
};
