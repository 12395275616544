import { useMenuFilter } from 'hooks/MenuFilters';
import { memo, ReactElement, useMemo } from 'react';
import '../styles/estilos.scss';

interface IProps {
  openWithClick?: boolean;
  children?: ReactElement | ReactElement[];
  style?: React.CSSProperties | undefined;
}

const MenuFilters = ({ children, openWithClick = false, style }: IProps) => {

  const { isOpenMenu, handlerStatusMenu } = useMenuFilter();

  const statusMenu: string = useMemo(() =>
    isOpenMenu === null ? 'default-width-menu-filters' :
      isOpenMenu ? 'openMenuFilters' : 'closeMenuFilters'
    , [isOpenMenu]);

  return (
    <div style={style} className={`container-menu-filters ${statusMenu}`}>
      <span className='icon-select-menu' onClick={() => handlerStatusMenu()}>
        <i className='fas fa-list-alt'></i>
      </span>
      <div className='box-filters' >
        {children}
      </div>
    </div>
  )
}

export default memo(MenuFilters)