import { IColumnProps } from "devextreme-react/data-grid";
import { ajustarDecimales } from "utils/Formatos";

export type FORMASPAGO_TYPES = "BECA" | "ASIM NOM" | "NOM";

interface IFormasPagoPreterminada {
  [key: string]: FORMASPAGO_TYPES;
}

export const DIA_PAGO_SEMANAL: number = 4; //Jueves
export const KEY_TIPO_PAGO: string = "tipoPago";
export const KEY_PERIODO: string = "periodo";

export const FORMAPAGO_PRETERMINADAS: IFormasPagoPreterminada =
{
  SEMANAL: "BECA",
  AGUINALDO: "NOM",
  QUINCENAL: "ASIM NOM",
  ESPECIAL: "NOM"
}

export type TIPOSPAGO_PRETERMINADO = "AGUINALDO" | "ESPECIAL" | "QUINCENAL" | "SEMANAL";

export const MONTO_CALCULO_VACACIONES: number = 0.25;

export const SKIP_TIPO_COMISIONES: string = "COMISIONES";

export const MESSAGE_LOADING: string = "Obteniendo Información...";

export type MethodSave = "PRENOMINA" | "NOMINA";

export type Reportes = "Billetiza" | "Recibos" | "Asimilados" | "Acumulado" | "Comisiones";

export type BILLETES = "500" | "200" | "100" | "50" | "20";

export type MODULESNOMINA = "PAGADA" | "PAGAR";

export type ROLES = "SuperAdmin" | "Admin" | "TH" | "Ventas";

export type MODALS = "acumulado" | "comisiones";

export const columsReporteComisiones: IColumnProps[] = [
  {
    dataField: "colaborador",
    dataType: "string"
  },
  {
    dataField: "cuenta",
    dataType: "string"
  },
  {
    dataField: "clabe",
    dataType: "string"
  },
  {
    dataField: "rfc",
    dataType: "string"
  },
  {
    dataField: "vendedor",
    dataType: "string"
  },
  {
    dataField: "total_A_Pagar",
    dataType: "number",
    format: ajustarDecimales
  }
]