import { useAuth } from "api/auth";
import { ITabs } from "functions/Tareas";
import { TareasService } from "api/tareas";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import {
  ITarea,
  IAddTarea,
  IEntidades,
  IUpdateTarea,
  IGetDataTareas,
  IGetDataSubtareas,
  IGetDataEntidades,
  IGetTareasEntidad,
  IGetDataResponsables,
  ISeguimientoEntidades,
  IGetDataSeguimientoClientes,
  IGetDataSeguimientoProveedores,
} from "interfaces/ITareas";
import { SyncService } from "api/sync";
import notify from "devextreme/ui/notify";
import { storage } from "../../../config/firebase.config";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

interface IUseTareas {
  tarea: ITarea;
  tabVisible: ITabs;
  modalStatus: boolean;
  dataTareas: IGetDataTareas;
  modalActividadStatus: boolean;
  setModalStatus(e: boolean): void;
  entidadDefined: IGetDataEntidades;
  responsables: IGetDataResponsables;
  getResponsables: () => Promise<void>;
  dataTareasEntidad: IGetTareasEntidad;
  handlerOnSelectTab: (tab: ITabs) => void;
  setModalActividadStatus(e: boolean): void;
  deleteTarea: (id: number) => Promise<void>;
  getSeguimientoClientes: () => Promise<void>;
  getEntidades: (tipo: string) => Promise<IEntidades[]>;
  addTarea: (tarea: IAddTarea) => Promise<void>;
  getSeguimientoProveedores: () => Promise<void>;
  getTareasEntidad: (id: string) => Promise<void>;
  seguimientoClientes: IGetDataSeguimientoClientes;
  handleEditTarea: (data: ITarea) => Promise<void>;
  updateTarea: (tarea: IUpdateTarea) => Promise<void>;
  seguimientoProveedores: IGetDataSeguimientoProveedores;
  onEditTarea: (data: IUpdateTarea, action: string) => Promise<void>;
  handleTareasEntidad: (data: ISeguimientoEntidades) => Promise<void>;
  onHandlerSync: (dataToSync: any[]) => Promise<void>;
  loading: boolean;
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  uploadFiles: (files: File[]) => Promise<{ success: boolean, url: string, name: string }[]>;
  modalArchivos: boolean;
  setModalArchivos(e: boolean): void;
  dataArchivos: any[];
  setDataArchivos: React.Dispatch<React.SetStateAction<any[]>>;
  handleArchivosTarea: (idTarea: number) => void;
  addArchivosTarea: (dataArchivos: any) => Promise<void>;
  deleteArchivoTarea: (dataArchivo: any) => Promise<void>;
  dataTareasSolPago: IGetDataTareas;
  getTareasSolicitudPago: () => Promise<void>;
  modalSubTareas: boolean;
  setModalSubTareas(e: boolean): void;
  dataSubTareas: IGetDataSubtareas;
  setDataSubTareas: React.Dispatch<React.SetStateAction<IGetDataSubtareas>>;
  handleSubTareas: (id: number, entidad_guid: string, tarea_padre: ITarea) => Promise<void>;
}

const initialStateTarea: ITarea = {
  Tarea_Id: 0,
  Tarea_Nombre: "",
  Tarea_Estatus: "",
  Tarea_Urgencia: "",
  Tarea_Tipo_Servicio: "",
  Entidad_Razon_Social: "",
  cMotion_Perfil_Colaborador_Nombre: "",
  cMotion_Perfil_Colaborador_Apellido_Paterno: "",
  cMotion_Perfil_Colaborador_Apellido_Materno: "",
  Estatus_Id: 0,
  Urgencia_Id: 0,
  Nombre_Tarea_Id: 0,
  Servicio_Id: 0,
  Tarea_Nota: "",
  Entidad_Tipo: "",
  Tarea_Fecha_Termino: new Date(),
  Tarea_Fecha_Llamada: new Date(),
  Tarea_Fecha_Creacion: new Date(),
  Tarea_Fecha_Ultima_Actualizacion: new Date(),
  Moneda_Id: 0,
  Tarea_Moneda: "",
  Tarea_Monto: 0,
};

const nombreTareaItems = [
  { Nombre_Tarea_Id: 1, Tarea_Nombre: "LLAMADA" },
  { Nombre_Tarea_Id: 2, Tarea_Nombre: "CORREO" },
  { Nombre_Tarea_Id: 3, Tarea_Nombre: "COBRANZA" },
  { Nombre_Tarea_Id: 4, Tarea_Nombre: "WHATSAPP" },
  { Nombre_Tarea_Id: 5, Tarea_Nombre: "ENVIO ESTADO DE CUENTA" },
  { Nombre_Tarea_Id: 6, Tarea_Nombre: "REVISION DE PORTAL" },
  { Nombre_Tarea_Id: 7, Tarea_Nombre: "SOLICITUD DE PAGO" },
  { Nombre_Tarea_Id: 8, Tarea_Nombre: "OTRO" },
];

const urgenciaItems = [
  { idUrgencia: 1, urgencia: "Baja" },
  { idUrgencia: 2, urgencia: "Media" },
  { idUrgencia: 3, urgencia: "Alta" },
];

const estatusItems = [
  { idEstatus: 1, estatus: "Pendiente" },
  { idEstatus: 2, estatus: "En Proceso" },
  { idEstatus: 3, estatus: "Terminada" },
];

const serviciosItems = [
  { Servicio_Id: 1, servicio: "Ocean" },
  { Servicio_Id: 2, servicio: "Air" },
  { Servicio_Id: 3, servicio: "Ground" },
  { Servicio_Id: 4, servicio: "Gastos" },
];

const MonedasItems = [
  { Moneda_Id: 1, Moneda_Nombre: "MXN" },
  { Moneda_Id: 2, Moneda_Nombre: "USD" },
  { Moneda_Id: 3, Moneda_Nombre: "EUR" },
];

const defaultTab: ITabs = {
  text: "Editar",
};

export const useTareas = (): IUseTareas => {
  const { User } = useAuth();
  const [dataTareas, setDataTareas] = useState<IGetDataTareas>({
    loading: false,
    data: [],
  });
  const [dataTareasSolPago, setDataTareasSolPago] = useState<IGetDataTareas>({
    loading: false,
    data: [],
  });
  const [dataSubTareas, setDataSubTareas] = useState<IGetDataSubtareas>({
    loading: false,
    data: [],
    Tarea_Padre_Id: 0,
    Entidad_Guid: "",
    Tarea_Padre: initialStateTarea,
  });
  const [responsables, setResponsables] = useState<IGetDataResponsables>({
    loading: false,
    data: [],
  });
  const [entidadDefined, setEntidadDefined] = useState<IGetDataEntidades>({
    loading: false,
    data: [],
  });
  const [seguimientoClientes, setSeguimientoClientes] =
    useState<IGetDataSeguimientoClientes>({ loading: false, data: [] });
  const [seguimientoProveedores, setSeguimientoProveedores] =
    useState<IGetDataSeguimientoProveedores>({ loading: false, data: [] });
  const [dataTareasEntidad, setDataTareasEntidad] = useState<IGetTareasEntidad>(
    { loading: false, data: [] }
  );
  const [modalStatus, setModalStatus] = useState(false);
  const [modalActividadStatus, setModalActividadStatus] = useState(false);
  const [tarea, setTarea] = useState<ITarea>({ ...initialStateTarea });
  const [tabVisible, setTabVisible] = useState<ITabs>(defaultTab);

  const tareasService = useMemo(() => TareasService(User), [User]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [modalArchivos, setModalArchivos] = useState(false);
  const [dataArchivos, setDataArchivos] = useState<any[]>([]);
  const [modalSubTareas, setModalSubTareas] = useState(false);

  const getTareas = useCallback(async (): Promise<void> => {
    setDataTareas({ loading: true, data: [] });
    const { data } = await tareasService.getTareas();
    setDataTareas({ loading: false, data });
  }, [tareasService]);

  useLayoutEffect(() => {
    getTareas();
  }, [getTareas]);

  const getResponsables = useCallback(async (): Promise<void> => {
    setResponsables({ loading: true, data: [] });
    const { data } = await tareasService.getResponsables();
    const responsablesTransformados = data.map((responsable) => ({
      colaborador: `${responsable.cMotion_Perfil_Colaborador_Nombre} ${responsable.cMotion_Perfil_Colaborador_Apellido_Paterno} ${responsable.cMotion_Perfil_Colaborador_Apellido_Materno}`,
      guid: responsable.cMotion_Perfil_Colaborador_Guid,
      colaborador_id: responsable.colaborador_id,
    }));
    setResponsables({ loading: false, data: responsablesTransformados });
  }, [tareasService]);

  useLayoutEffect(() => {
    getResponsables();
  }, [getResponsables]);

  const getEntidades = useCallback(
    async (tipo: string): Promise<IEntidades[]> => {
      const { data } = await tareasService.getEntidades(tipo);
      setEntidadDefined({ loading: false, data });
      return data;
    },
    [tareasService]
  );

  const addTarea = useCallback(
    async (tarea: IAddTarea): Promise<void> => {
      await tareasService.addTarea(tarea);
      await getTareas();
    },
    [tareasService, getTareas]
  );

  const handleEditTarea = useCallback(
    async (data: ITarea): Promise<void> => {
      const updatedData = { ...data };

      const urgenciaItem = urgenciaItems.find(
        (item) => item.urgencia === data.Tarea_Urgencia
      );
      const estatusItem = estatusItems.find(
        (item) => item.estatus === data.Tarea_Estatus
      );

      const nombreTareaItem = nombreTareaItems.find(
        (item) => item.Tarea_Nombre === data.Tarea_Nombre
      );

      const servicioItem = serviciosItems.find(
        (item) => item.servicio === data.Tarea_Tipo_Servicio
      );

      const monedaItem = MonedasItems.find(
        (item) => item.Moneda_Nombre === data.Tarea_Moneda
      );

      if (urgenciaItem) {
        updatedData.Urgencia_Id = urgenciaItem.idUrgencia;
      }

      if (estatusItem) {
        updatedData.Estatus_Id = estatusItem.idEstatus;
      }

      if (nombreTareaItem) {
        updatedData.Nombre_Tarea_Id = nombreTareaItem.Nombre_Tarea_Id;
      } else {
        updatedData.Nombre_Tarea_Id = 6;
      }

      if (servicioItem) {
        updatedData.Servicio_Id = servicioItem.Servicio_Id;
      }

      if (monedaItem) {
        updatedData.Moneda_Id = monedaItem.Moneda_Id;
      }

      await getResponsables();
      await getEntidades(data.Entidad_Tipo);
      setModalStatus(true);
      setTarea(updatedData);
    },
    [getResponsables, getEntidades]
  );

  const updateTarea = useCallback(
    async (tarea: IUpdateTarea): Promise<void> => {
      await tareasService.updateTarea(tarea);
      await getTareas();
      setModalStatus(false);
    },
    [tareasService, getTareas]
  );

  const handlerOnSelectTab = useCallback((tab: ITabs): void => {
    setTabVisible(tab);
  }, []);

  const deleteTarea = useCallback(
    async (id: number): Promise<void> => {
      await tareasService.deleteTarea(id);
      await getTareas();
    },
    [tareasService, getTareas]
  );

  const getSeguimientoClientes = useCallback(async (): Promise<void> => {
    setSeguimientoClientes({ loading: true, data: [] });
    const { data } = await tareasService.getSeguimientoClientes();
    setSeguimientoClientes({ loading: false, data });
  }, [tareasService]);

  const getSeguimientoProveedores = useCallback(async (): Promise<void> => {
    setSeguimientoProveedores({ loading: true, data: [] });
    const { data } = await tareasService.getSeguimientoProveedores();
    setSeguimientoProveedores({ loading: false, data });
  }, [tareasService]);

  const getTareasEntidad = useCallback(
    async (id: string): Promise<void> => {
      setDataTareasEntidad({ loading: true, data: [] });
      const { data } = await tareasService.getTareasEntidad(id);
      setDataTareasEntidad({ loading: false, data });
    },
    [tareasService]
  );

  const handleTareasEntidad = useCallback(
    async (data: ISeguimientoEntidades): Promise<void> => {
      await getTareasEntidad(data.Entidad_Guid);
      setModalActividadStatus(true);
    },
    [getTareasEntidad]
  );

  const onEditTarea = useCallback(
    async (data: IUpdateTarea, action: string) => {
      switch (action) {
        case "update":
          await updateTarea(data);
          await getTareasEntidad(data.Entidad_Guid);
          break;
        case "remove":
          await deleteTarea(data.Tarea_Id);
          await getTareasEntidad(data.Entidad_Guid);
          break;
        default:
          return;
      }
    },
    [updateTarea, deleteTarea, getTareasEntidad]
  );

  const onHandlerSync = useCallback(
    async (dataToSync: any[]) => {
      setLoading(true);
      const syncData = dataToSync.map((item) => ({
        Tipo: item.reporte_Sync_Tipo,
        Guid: item.reporte_Sync_Guid,
        isDeleted: false,
      }));
      try {
        await SyncService().syncAdd(syncData, User);
        notify("Todos los datos se agregaron a la cola", "success", 2000);
        setData([]);
      } catch (error) {
        console.log(error);
        notify("Error al sincronizar", "error", 2000);
      } finally {
        setLoading(false);
      }
    },
    [User]
  );

  const uploadFiles = async (files: File[]) => {
    const uploadPromises = files.map(async (file) => {
      try {
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
        const fileNameWithoutExtension = fileName.substring(0, fileName.lastIndexOf('.'));
        const uniqueFileName = `${fileNameWithoutExtension}_${Date.now()}${fileExtension}`;
        const imageRef = ref(storage, `tareas/${uniqueFileName}`);
        await uploadBytes(imageRef, file);
        const url = await getDownloadURL(imageRef);
        return { success: true, url, name: file.name };
      } catch (error) {
        console.error('Error uploading file:', error);
        return { success: false, url: '', name: file.name };
      }
    });
    return Promise.all(uploadPromises);
  };

  const handleArchivosTarea = async (idTarea: number) => {
    const { data } = await tareasService.getFilesTarea(idTarea);
    const dataFormated = {...data, Tarea_Id: idTarea};
    console.log(dataFormated);
    setDataArchivos(dataFormated);
    setModalArchivos(true);
  }

  const addArchivosTarea = async (dataArchivos: any) => {
    await tareasService.addArchivosTarea(dataArchivos);
    const { data } = await tareasService.getFilesTarea(dataArchivos.Tarea_Id);
    const dataFormated = {...data, Tarea_Id: dataArchivos.Tarea_Id};
    setDataArchivos(dataFormated);
  };

  const deleteArchivoTarea = async (dataArchivo: any) => {
    await tareasService.deleteArchivoTarea(dataArchivo.id);
    const { data } = await tareasService.getFilesTarea(dataArchivo.Tarea_Id);
    const dataFormated = {...data, Tarea_Id: dataArchivo.Tarea_Id};
    setDataArchivos(dataFormated);
  }

  const getTareasSolicitudPago = useCallback(async (): Promise<void> => {
    setDataTareasSolPago({ loading: true, data: [] });
    const { data } = await tareasService.getTareasSolicitudPago();
    setDataTareasSolPago({ loading: false, data });
  }, [tareasService]);

  const handleSubTareas = useCallback(async (id: number, entidad_guid: string, tarea_padre: ITarea): Promise<void> => {
    setDataSubTareas({
      loading: true,
      data: [],
      Tarea_Padre_Id: id,
      Entidad_Guid: entidad_guid,
      Tarea_Padre: tarea_padre,
     });
    setModalSubTareas(true);
    const { data } = await tareasService.getSubtareas(id);
    setDataSubTareas({
      loading: false,
      data,
      Tarea_Padre_Id: id,
      Entidad_Guid: entidad_guid,
      Tarea_Padre: tarea_padre,
    });
  }, [tareasService]);

  return {
    tarea,
    dataTareas,
    responsables,
    modalStatus,
    entidadDefined,
    tabVisible,
    setModalStatus,
    getEntidades,
    getResponsables,
    addTarea,
    handleEditTarea,
    updateTarea,
    handlerOnSelectTab,
    deleteTarea,
    seguimientoClientes,
    seguimientoProveedores,
    getSeguimientoClientes,
    getSeguimientoProveedores,
    dataTareasEntidad,
    getTareasEntidad,
    handleTareasEntidad,
    modalActividadStatus,
    setModalActividadStatus,
    onEditTarea,
    onHandlerSync,
    loading,
    data,
    setData,
    uploadFiles,
    modalArchivos,
    setModalArchivos,
    dataArchivos,
    setDataArchivos,
    handleArchivosTarea,
    addArchivosTarea,
    deleteArchivoTarea,
    dataTareasSolPago,
    getTareasSolicitudPago,
    modalSubTareas,
    setModalSubTareas,
    dataSubTareas,
    setDataSubTareas,
    handleSubTareas,
  };
};
